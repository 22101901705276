import React from "react";
import { Link } from "react-router-dom";
import ImageWithModal from "../../../common/ImageWithModal";
import dragDropImageImg from "../../../../images/docs/using/drag-drop-image.png";
import promptImageImg from "../../../../images/docs/using/prompt-image.png";

const UsingPromptImageVision = () => {
	return (
		<div>
			<h1 className="text-4xl font-bold mb-6">Using Prompt Image Vision</h1>

			<p>
				Protocraft allows you to include images in your prompts, enabling you to ask questions about or analyze visual content. This feature supports
				both drag-and-drop functionality and selecting images from the file browser.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Drag and Drop Images</h2>
			<p>To include an image in your prompt using drag and drop:</p>
			<ol className="list-decimal list-inside ml-4 mb-4">
				<li>Locate the image file on your computer.</li>
				<li>Click and drag the image file into the chat interface.</li>
				<li>Drop the image in the designated area or directly into the prompt input field.</li>
			</ol>
			<ImageWithModal src={dragDropImageImg} alt="Drag and Drop Image" />

			<h2 className="text-2xl font-semibold mt-8 mb-4">Using Images from the File Browser</h2>
			<p>To include an image from the file browser:</p>
			<ol className="list-decimal list-inside ml-4 mb-4">
				<li>
					Open the file browser by clicking the "File Browser" button. Read more:{" "}
					<Link to="/docs/file-browser-overview" className="text-blue-500 hover:underline">
						file browser feature
					</Link>
					.
				</li>
				<li>Navigate to the desired image file in your project directory.</li>
				<li>Check the checkbox next to the image file to include it in your prompt.</li>
			</ol>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Prompting with Images</h2>
			<p>Once you've included an image in your prompt, you can ask questions, prompt tasks, or request analysis about the image content. For example:</p>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>"Describe the main elements in this image."</li>
				<li>"Can you edit the code so that the element in this image is red?"</li>
				<li>"What colors are predominant in this picture?"</li>
				<li>"Can you identify any text in this image?"</li>
				<li>"Compare this image to the previous one we discussed."</li>
			</ul>
			<ImageWithModal src={promptImageImg} alt="Prompting with Image" />

			<h2 className="text-2xl font-semibold mt-8 mb-4">Best Practices</h2>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>Ensure your images are in a supported format (e.g., JPG, PNG, GIF).</li>
				<li>Use clear, high-quality images for better analysis results.</li>
				<li>Be specific in your questions or requests about the image content.</li>
				<li>Remember that the LLM can analyze visual content but cannot generate or edit images.</li>
			</ul>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Limitations</h2>
			<p>Keep in mind the following limitations when using prompt image vision:</p>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>The LLM can analyze images but cannot generate, edit, or manipulate them.</li>
				<li>Very large image files may take longer to process or may not be supported.</li>
				<li>Each LLM has a different limit on the number of images it can process in a single request.</li>
				<li>Each LLM also charges a different price per image and image size for vision processing.</li>
				<li>The accuracy of image analysis depends on the quality and clarity of the image.</li>
			</ul>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Next Steps</h2>
			<p>
				Now that you know how to use prompt image vision, you might want to explore other Protocraft features, such as{" "}
				<Link to="/docs/using-tools" className="text-blue-500 hover:underline">
					Using Tools
				</Link>{" "}
				or{" "}
				<Link to="/docs/using-templates" className="text-blue-500 hover:underline">
					Using Templates
				</Link>
				.
			</p>
		</div>
	);
};

export default UsingPromptImageVision;
