import React from "react";
import { Link } from "react-router-dom";

const HowToDownload = () => {
	return (
		<div>
			<h1 className="text-4xl font-bold mb-6">How to Download Protocraft</h1>

			<h2 className="text-2xl font-semibold mt-6 mb-4">Supported Operating Systems and File Types</h2>
			<p className="mb-4">
				Protocraft is available for Windows, macOS, and Linux. We provide different file types for each operating system to ensure compatibility with
				your device:
			</p>
			<ul className="list-disc pl-6 mb-4">
				<li>
					<strong>Windows:</strong> .exe installer (x86_64 and arm64)
				</li>
				<li>
					<strong>macOS:</strong> .dmg disk image (Intel x86_64 and Apple Silicon arm64)
				</li>
				<li>
					<strong>Linux:</strong> .deb package (amd64 and arm64) and .rpm package (x86_64 and arm64)
				</li>
			</ul>

			<h2 className="text-2xl font-semibold mt-6 mb-4">How to Download</h2>
			<ol className="list-decimal pl-6 mb-4">
				<li>
					Visit the{" "}
					<Link to="/downloads" className="text-purple-600 hover:text-purple-800">
						Protocraft Downloads page
					</Link>
					.
				</li>
				<li>Select the appropriate version for your operating system and architecture.</li>
				<li>Click the download button for your chosen version.</li>
				<li>Once the download is complete, follow the installation instructions for your operating system:</li>
			</ol>
			<ul className="list-disc pl-10 mb-4">
				<li>
					<strong>Windows:</strong> Run the .exe installer and follow the prompts.
				</li>
				<li>
					<strong>macOS:</strong> Open the .dmg file and drag the Protocraft app to your Applications folder.
				</li>
				<li>
					<strong>Linux:</strong> Use your package manager to install the .deb or .rpm file.
				</li>
			</ul>

			<h2 className="text-2xl font-semibold mt-6 mb-4">Build Signing Information</h2>
			<div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-4" role="alert">
				<p className="font-bold mb-2">Important Notice:</p>
				<ul className="list-disc pl-5 space-y-1">
					<li>Mac builds: Officially signed for smooth installation on macOS.</li>
					<li>
						Windows builds: Not yet signed with Extended Validation (EV).
						<ul className="list-[circle] pl-5 mt-1 space-y-1">
							<li>We are in the process of obtaining EV signing for Windows builds.</li>
							<li>Windows users may see an "unknown publisher" warning during installation.</li>
						</ul>
					</li>
				</ul>
			</div>
			<p className="mb-4">
				We are committed to providing a secure and trustworthy installation experience across all platforms. The current lack of EV signing on Windows
				does not affect the functionality or security of the Protocraft application itself.
			</p>

			<h2 className="text-2xl font-semibold mt-6 mb-4">Free Evaluation and Licensing</h2>
			<p className="mb-4">
				Protocraft offers a free evaluation period for users to try out the software before purchasing a license. For detailed information about our
				free evaluation, licensing options, and pricing, please visit our{" "}
				<Link to="/docs/licensing-and-pricing" className="text-purple-600 hover:text-purple-800">
					Licensing and Pricing
				</Link>{" "}
				page.
			</p>

			<p className="mt-6">
				If you encounter any issues during the download or installation process, please don't hesitate to contact our support team at{" "}
				<a href="mailto:support@protocraft.ai" className="text-purple-600 hover:text-purple-800">
					support@protocraft.ai
				</a>
				.
			</p>
		</div>
	);
};

export default HowToDownload;
