import React from "react";
import ImageWithModal from "../../../common/ImageWithModal";
import includeFilesImg from "../../../../images/docs/file-browser/include-files.png";
import removeIncludedFileImg from "../../../../images/docs/file-browser/remove-included-file.png";
import includeFilesPromptImg from "../../../../images/docs/file-browser/include-files-prompt.png";

const IncludingFilesInPrompts = () => {
	return (
		<div>
			<h1 className="text-4xl font-bold mb-6">Including Files In Prompts</h1>

			<p className="mb-4">
				To include files in your prompt context, you can easily select them in the File Browser. This allows you to provide additional context to the
				prompt, enhancing its accuracy and relevance, and also is very useful when allowing the LLM to edit the files directly.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Adding Files to the Prompt</h2>
			<ImageWithModal src={includeFilesImg} alt="Include Files" className="mb-4" />
			<p className="mb-4">
				Click on the checkbox next to the file you want to include in the prompt context. This action will add the file to the list of included files.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Removing Files from the Prompt</h2>
			<ImageWithModal src={removeIncludedFileImg} alt="Remove Included File" className="mb-4" />
			<p className="mb-4">
				To remove a file, hover over the included file in the list. A red "X" will appear. Click on the "X" to remove the file from the prompt context.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Using Included Files in Prompts</h2>
			<ImageWithModal src={includeFilesPromptImg} alt="Include Files Prompt" className="mb-4" />
			<p className="mb-4">
				Once you have included the files you want to use in the prompt, you can use them in your prompts. The files will be included in the prompt
				context, and the LLM will have access to them.
			</p>
		</div>
	);
};

export default IncludingFilesInPrompts;
