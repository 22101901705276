import React from "react";
import ImageWithModal from "../../../common/ImageWithModal";
import manageToolsImg from "../../../../images/docs/using/tools-popup.png";
import toolUsageImg from "../../../../images/docs/using/tools.png";
import toolResultImg from "../../../../images/docs/using/tools-expanded.png";
import { Link } from "react-router-dom";

const UsingTools = () => {
	return (
		<div>
			<h1 className="text-4xl font-bold mb-6">Using Tools</h1>

			<p className="mb-4">
				Tools in Protocraft enhance the AI's capabilities by allowing it to interact with your files and file system, external systems, and perform
				specific tasks. This feature enables the AI to access real-time information, manipulate files, and execute various operations to act more
				autonomously and leverage the agency of the AI.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Managing Tools</h2>
			<ImageWithModal src={manageToolsImg} alt="Manage Tools" className="mb-4" />
			<p className="mb-4">To manage tools:</p>
			<ol className="list-decimal list-inside ml-4 mb-4">
				<li>Click on the "Tools" button at the bottom of the chat interface.</li>
				<li>In the "Manage Tools" panel, you'll see a list of available tools.</li>
				<li>Check the boxes next to the tools you want to enable for the AI to use.</li>
			</ol>

			<p className="mb-4">
				<strong>Note:</strong> This feature is experimental and may not work as expected for all LLMs. It currently works for OpenAI and Anthropic
				models, and support is experimental for OpenRouter and known to work for OpenRouter's OpenAI models.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Available Tools</h2>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>
					<strong>Read Files:</strong> Read one or more local files within the current working directory.
				</li>
				<li>
					<strong>List Directory:</strong> List all of the files and folders in a directory.
				</li>
				<li>
					<strong>Create File:</strong> Create a new file on the file system.
				</li>
				<li>
					<strong>Create Folder:</strong> Create a new folder on the file system.
				</li>
				<li>
					<strong>Edit File:</strong> Edit an existing file on the file system.
				</li>
				<li>
					<strong>Google Search:</strong> Search Google for information on the Internet.
					<ul className="list-disc list-inside ml-4">
						<li>
							<strong>Note:</strong> This tool is only available if you have provided the necessary API keys. See{" "}
							<Link to="/docs/setting-up-google-search" className="text-blue-500 hover:underline">
								Setting Up Google Search
							</Link>{" "}
							for more information.
						</li>
					</ul>
				</li>
				<li>
					<strong>Browse Internet:</strong> Browse a web page using Selenium and retrieve its content.
				</li>
			</ul>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Using Tools in Chat</h2>
			<ImageWithModal src={toolUsageImg} alt="Tool Usage" className="mb-4" />
			<p className="mb-4">
				Once tools are enabled, the AI can use them automatically when needed. For example, if you ask about current information that the AI might not
				have in its training data, it can use the Google Search tool to find up-to-date information.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Tool Results</h2>
			<ImageWithModal src={toolResultImg} alt="Tool Result" className="mb-4" />
			<p className="mb-4">When a tool is used, you'll see:</p>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>The name of the tool used</li>
				<li>The arguments passed to the tool</li>
				<li>The output or result of the tool's operation</li>
			</ul>
			<p className="mb-4">The AI will then use this information to formulate its response to your query.</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Benefits of Using Tools</h2>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>
					<strong>Up-to-date Information:</strong> Tools like Google Search allow the AI to access current information.
				</li>
				<li>
					<strong>File Management:</strong> File-related tools enable the AI to interact with your local file system.
				</li>
				<li>
					<strong>Web Interaction:</strong> The Browse Internet tool allows the AI to access and analyze web content.
				</li>
			</ul>

			<p className="mt-4">
				By leveraging these tools, you can significantly expand the AI's capabilities and get more accurate, up-to-date, and context-aware assistance in
				Protocraft.
			</p>
		</div>
	);
};

export default UsingTools;
