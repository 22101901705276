import React from "react";
import { Link } from "react-router-dom";
import dataManagementImg from "../../../../images/docs/settings/data-mgmt.png";
import ImageWithModal from "../../../common/ImageWithModal";

const DataManagement = () => {
	return (
		<div>
			<h1 className="text-4xl font-bold mb-6">Data Management</h1>

			<p>
				Protocraft provides tools to manage your personal data, configurations, and settings. This page will guide you through the various data
				management options available in Protocraft.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Data Management Settings</h2>
			<p>
				In the Protocraft settings, navigate to the "Data Management" section. Here, you'll find options to export, import, clear, and reset your data.
			</p>

			<ImageWithModal src={dataManagementImg} alt="Data Management Settings" />

			<h2 className="text-2xl font-semibold mt-8 mb-4">Exporting Data</h2>
			<p>To export all your Protocraft data:</p>
			<ol className="list-decimal list-inside ml-4 mb-4">
				<li>Click the "Export All Data" button.</li>
				<li>Choose a location on your computer to save the exported data file.</li>
			</ol>
			<p>
				This export includes your chat history, configurations, and settings, but specifically does not include your API Keys - these remain stored
				encrypted and are not exportable.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Importing Data</h2>
			<p>To import previously exported data:</p>
			<ol className="list-decimal list-inside ml-4 mb-4">
				<li>Click the "Choose File" button under the "Import Data" section.</li>
				<li>Select the exported data file from your computer.</li>
				<li>Click the "Import Data" button to load the data into Protocraft.</li>
			</ol>
			<p>
				Note: Importing data will merge your current data with the imported data. Make sure to export your current data before importing if you want to
				preserve the current state prior to combination.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Clearing Data</h2>
			<p>To clear all your Protocraft data:</p>
			<ol className="list-decimal list-inside ml-4 mb-4">
				<li>Click the "Clear All Data" button under the "Clear Data" section.</li>
				<li>Confirm the action when prompted.</li>
			</ol>
			<p>
				Warning: This action will delete all your workspaces and chat history and cannot be undone. Consider exporting your data before clearing if you
				might need it later.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Resetting Configuration</h2>
			<p>To reset all Protocraft configurations to their default state:</p>
			<ol className="list-decimal list-inside ml-4 mb-4">
				<li>Click the "Reset Configuration" button under the "Reset Configuration" section.</li>
				<li>Confirm the action when prompted.</li>
			</ol>
			<p>
				Warning: This will completely wipe all keys, local & custom LLMs, and application preferences and settings to their factory state. This action
				cannot be undone, and the configurations can not be exported or backed up.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Data Privacy and Security</h2>
			<p>
				Protocraft stores all your data locally on your machine. No personal data is sent to external servers. However, always be cautious when
				exporting data, especially if it contains sensitive information.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Next Steps</h2>
			<p>
				After managing your data, you might want to review your other settings, such as{" "}
				<Link to="/docs/setting-up-api-keys" className="text-blue-500 hover:underline">
					API Keys
				</Link>{" "}
				or{" "}
				<Link to="/docs/always-ignore-files" className="text-blue-500 hover:underline">
					Always Ignore Files
				</Link>
				.
			</p>
		</div>
	);
};

export default DataManagement;
