import React, { createContext, useState, useContext, useEffect, ReactNode } from "react";
import { call } from "../services/api";

interface AuthContextType {
	email: string;
	isLoggedIn: boolean;
	loginCode?: string;
	acceptedEula: boolean;
	setEmail: (email: string) => void;
	signIn: (email: string) => Promise<void>;
	verify: (code: string) => Promise<any>;
	resendVerification: () => Promise<void>;
	logout: () => void;
	acceptEula: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [email, setEmail] = useState("");
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [loginCode, setLoginCode] = useState<string | undefined>(undefined);
	const [acceptedEula, setAcceptedEula] = useState(false);

	useEffect(() => {
		const loadStoredAuth = async () => {
			const storedAuth = localStorage.getItem("protocraft-auth");
			if (storedAuth) {
				const { email, code, timestamp } = JSON.parse(storedAuth);
				const currentTime = new Date().getTime();
				if (currentTime - timestamp < 45 * 60 * 1000) {
					// 45 minutes in milliseconds
					try {
						const response: any = await call("account/get-info", "POST", { email, code });
						if (response.email) {
							setEmail(email);
							setLoginCode(code);
							setIsLoggedIn(true);
							setAcceptedEula(response.accepted_eula);
						} else {
							throw new Error("Invalid stored credentials");
						}
					} catch (error) {
						console.error("Error verifying stored credentials:", error);
						localStorage.removeItem("protocraft-auth");
					}
				} else {
					localStorage.removeItem("protocraft-auth");
				}
			}
		};

		loadStoredAuth();
	}, []);

	const storeAuthInfo = (email: string, code: string) => {
		const authInfo = {
			email,
			code,
			timestamp: new Date().getTime(),
		};
		localStorage.setItem("protocraft-auth", JSON.stringify(authInfo));
	};

	const signIn = async (email: string) => {
		await call("account/sign-in", "POST", { email });
		setEmail(email);
	};

	const verify = async (code: string) => {
		const response: any = await call("account/get-info", "POST", { email, code, "generate-trial-key": true });
		if (response.email) {
			setIsLoggedIn(true);
			setLoginCode(code);
			setAcceptedEula(response.accepted_eula);
			storeAuthInfo(email, code);
		}
		return response;
	};

	const logout = () => {
		setEmail("");
		setIsLoggedIn(false);
		setLoginCode(undefined);
		setAcceptedEula(false);
		localStorage.removeItem("protocraft-auth");
	};

	const resendVerification = async () => {
		await call("account/resend-verification", "POST", { email });
	};

	const acceptEula = async () => {
		if (!loginCode) {
			throw new Error("User not authenticated");
		}
		await call("account/accept-eula", "POST", { email, code: loginCode });
		setAcceptedEula(true);
	};

	const value = {
		email,
		isLoggedIn,
		loginCode,
		acceptedEula,
		setEmail,
		signIn,
		verify,
		resendVerification,
		logout,
		acceptEula,
	};

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
	const context = useContext(AuthContext);
	if (context === undefined) {
		throw new Error("useAuth must be used within an AuthProvider");
	}
	return context;
};
