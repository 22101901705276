import React from "react";
import { Link } from "react-router-dom";
import ImageWithModal from "../../../common/ImageWithModal";
import projectIgnoreRulesImg from "../../../../images/docs/using/project-ignore-rules.png";

const ProjectIgnoreRules = () => {
	return (
		<div>
			<h1 className="text-4xl font-bold mb-6">Project Ignore Rules</h1>

			<p>
				Protocraft allows you to set up project-specific ignore rules for files and directories. These rules are applied to the current workspace,
				allowing you to customize which files are included or excluded for each project.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Project Ignore Rules Settings</h2>
			<p>
				To access the Project Ignore Rules settings, open the File Browser in your Protocraft workspace. You'll find the Project Ignore Rules option in
				the settings menu (gear icon) at the top of the File Browser panel.
			</p>

			<ImageWithModal src={projectIgnoreRulesImg} alt="Project Ignore Rules Settings" />

			<h2 className="text-2xl font-semibold mt-8 mb-4">Setting Up Project Ignore Rules</h2>
			<p>To set up project-specific ignore rules:</p>
			<ol className="list-decimal list-inside ml-4 mb-4">
				<li>Open the Project Ignore Rules settings from the File Browser.</li>
				<li>In the text area, enter one rule per line.</li>
				<li>
					Use the same syntax as .gitignore files. For example:
					<ul className="list-disc list-inside ml-8 mb-2">
						<li>
							To ignore a specific file: <code>filename.ext</code>
						</li>
						<li>
							To ignore a directory: <code>directory_name/</code>
						</li>
						<li>
							To ignore all files with a certain extension: <code>*.ext</code>
						</li>
					</ul>
				</li>
				<li>
					You can use comments by starting a line with <code>#</code>.
				</li>
				<li>After entering your rules, click the "Save & Apply" button to apply the changes to your current workspace.</li>
			</ol>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Impact of Project Ignore Rules</h2>
			<p>Files and directories matching these ignore rules will be:</p>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>Excluded from the file browser in the current workspace</li>
				<li>Not included in chat initialization directory listings for this workspace</li>
				<li>Ignored when Protocraft is scanning or processing files in this specific workspace</li>
			</ul>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Project vs. Global Ignore Rules</h2>
			<p>
				Project Ignore Rules are specific to each workspace, while{" "}
				<Link to="/docs/always-ignore-files" className="text-blue-500 hover:underline">
					Always Ignore Files
				</Link>{" "}
				settings apply globally across all workspaces. This allows you to have both global rules and project-specific rules.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Use Cases for Project Ignore Rules</h2>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>Ignore large datasets or binary files specific to a project</li>
				<li>Exclude test directories or files that are only relevant to the current project</li>
				<li>Ignore project-specific configuration files or logs</li>
				<li>Customize ignore rules based on the programming language or framework used in the project</li>
			</ul>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Best Practices</h2>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>Start with broader rules and refine as needed</li>
				<li>Regularly review and update your project ignore rules as your project evolves</li>
				<li>Use comments to explain complex ignore patterns or the reasoning behind certain rules</li>
				<li>Consider version controlling your project ignore rules if they are essential to your workflow</li>
			</ul>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Next Steps</h2>
			<p>
				After setting up your project ignore rules, you might want to explore other Protocraft features, such as{" "}
				<Link to="/docs/using-files" className="text-blue-500 hover:underline">
					Using Files
				</Link>{" "}
				or{" "}
				<Link to="/docs/including-files-in-prompts" className="text-blue-500 hover:underline">
					Including Files in Prompts
				</Link>
				.
			</p>
		</div>
	);
};

export default ProjectIgnoreRules;
