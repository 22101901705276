import React from "react";
import ImageWithModal from "../../../common/ImageWithModal";

// Import images (you'll need to add these to your project)
import headerOptionsImg from "../../../../images/docs/file-browser/header-default.png";
import headerEditingImg from "../../../../images/docs/file-browser/header-editing.png";
import headerPreviewImg from "../../../../images/docs/file-browser/header-preview.png";

const FileViewerOptions = () => {
	return (
		<div>
			<h1 className="text-4xl font-bold mb-6">File Viewer Options</h1>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Header Option Views</h2>
			<p className="mb-4">The file viewer header has three different views. Default, Editing, and Preview.</p>
			<ImageWithModal src={headerOptionsImg} alt="File Viewer Options" />
			<ImageWithModal src={headerEditingImg} alt="File Viewer Options" />
			<ImageWithModal src={headerPreviewImg} alt="File Viewer Options" />

			<h2 className="text-2xl font-semibold mt-8 mb-4">Left Side Options</h2>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>
					<strong>Wrap Lines:</strong> Toggles line wrapping for text files, making it easier to read long lines without horizontal scrolling.
				</li>
				<li>
					<strong>Edit File:</strong> Available for text and code files. Switches the viewer to edit mode, allowing you to make changes to the file.
				</li>
				<li>
					<strong>Save File Changes:</strong> <em>Appears in edit mode.</em> Saves the changes you've made to the file.
				</li>
				<li>
					<strong>Cancel Changes:</strong> <em>Appears in edit mode.</em> Discards any changes and exits edit mode.
				</li>
			</ul>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Right Side Options</h2>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>
					<strong>Take Screenshot:</strong> <em>Appears in preview mode.</em> Captures the current view and attaches it to the chat prompt, allowing
					you to reference the current visual state in your conversation.
				</li>
				<li>
					<strong>View Preview:</strong> <em>Appears if the file is previewable. Currently HTML or SVG.</em> Toggles between the raw file content and
					a rendered preview.
				</li>
				<li>
					<strong>Reset Zoom Level / Font Size:</strong> Returns the zoom level or font size to the default setting.
				</li>
				<li>
					<strong>Decrease Zoom Level / Font Size:</strong> Reduces the zoom level for images or font size for text files.
				</li>
				<li>
					<strong>Increase Zoom Level / Font Size:</strong> Enlarges the zoom level for images or font size for text files.
				</li>
				<li>
					<strong>Refresh File Content:</strong> Reloads the file content, ensuring you're viewing the most up-to-date version.
				</li>
			</ul>

			<p className="mt-4">
				These options provide you with flexibility in how you view and interact with files, making it easier to work with various file types within
				Protocraft.
			</p>
		</div>
	);
};

export default FileViewerOptions;
