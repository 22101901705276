import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { FaEnvelope } from "react-icons/fa";
import workWithFilesImg from "../../images/home/work-with-files.png";

const ProductDisplay = () => {
	return (
		<section className="flex flex-col items-center">
			<div className="product flex flex-col items-center bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 max-w-3xl">
				<img src={workWithFilesImg} alt="Work with Files" className="w-full h-auto rounded-lg shadow-lg" />
				<div className="description text-center mt-4">
					<h3 className="text-2xl font-bold text-purple-600 dark:text-purple-400">Protocraft - Individual License</h3>
					<h5 className="text-xl text-gray-700 dark:text-gray-300">$39.00 USD</h5>
					<ul className="list-disc list-inside text-lg text-left text-gray-700 dark:text-gray-300 mt-4">
						<li>Perpetual-use license</li>
						<li>1 year of software updates</li>
						<li>Use on Mac, Windows, or Linux</li>
					</ul>
				</div>
			</div>
		</section>
	);
};

const Checkout = () => {
	const [showCryptoEmail, setShowCryptoEmail] = useState(false);
	const [cryptoEmail, setCryptoEmail] = useState("");
	const [emailError, setEmailError] = useState("");

	useEffect(() => {
		if (process.env.NODE_ENV === "production") {
			ReactGA.event({
				category: "conversion",
				action: "begin_checkout",
				label: "User initiated checkout",
			});
		}
	}, []);

	const handleCryptoCheckout = () => {
		if (process.env.NODE_ENV === "production") {
			ReactGA.event({
				category: "conversion",
				action: "begin_crypto_checkout",
				label: "User initiated crypto checkout",
			});
		}
		setShowCryptoEmail(true);
	};

	const validateEmail = (email) => {
		const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		return re.test(String(email).toLowerCase());
	};

	const handleCryptoEmailSubmit = (e) => {
		e.preventDefault();
		if (validateEmail(cryptoEmail)) {
			setEmailError("");
			// Submit the form
			e.target.submit();
		} else {
			setEmailError("Please enter a valid email address");
		}
	};

	return (
		<div className="container mx-auto p-6 flex flex-col gap-6">
			<h2 className="text-4xl font-bold text-center text-purple-600 dark:text-purple-400">Purchase Protocraft</h2>
			<div className="mx-auto flex flex-col justify-center items-center">
				{!showCryptoEmail ? (
					<>
						<ProductDisplay />
						<div className="flex flex-col sm:flex-row gap-4 mt-6">
							<form action="/stripe/create-checkout-session" method="POST">
								<button
									type="submit"
									className="px-6 py-3 bg-gradient-to-r from-purple-600 to-pink-500 hover:from-purple-700 hover:to-pink-600 text-white text-lg font-semibold rounded-lg transition duration-200"
								>
									Checkout with Stripe
								</button>
							</form>
							<button
								onClick={handleCryptoCheckout}
								className="px-6 py-3 bg-gradient-to-r from-blue-500 to-green-400 hover:from-blue-600 hover:to-green-500 text-white text-lg font-semibold rounded-lg transition duration-200"
							>
								Pay with Crypto
							</button>
						</div>
					</>
				) : (
					<div className="mx-auto bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg max-w-md">
						<h3 className="text-2xl font-semibold mb-4 flex items-center text-purple-600 dark:text-purple-400">
							<FaEnvelope className="mr-2" /> Pay with Crypto
						</h3>
						<p className="mb-4 text-gray-700 dark:text-gray-300">Please enter your email address to continue with the crypto payment.</p>
						<form onSubmit={handleCryptoEmailSubmit} action="/coinbase/create-charge" method="POST" className="flex flex-col gap-2">
							<input
								type="email"
								name="email"
								value={cryptoEmail}
								onChange={(e) => setCryptoEmail(e.target.value)}
								placeholder="Enter your email"
								className="w-full p-3 mb-4 border rounded-lg dark:bg-gray-700 dark:text-white"
								required
							/>
							{emailError && <p className="text-red-500 text-sm mb-2">{emailError}</p>}
							<button
								type="submit"
								className="w-full p-3 bg-gradient-to-r from-blue-500 to-green-400 hover:from-blue-600 hover:to-green-500 text-white text-lg font-semibold rounded-lg transition duration-200"
							>
								Continue to Crypto Payment
							</button>
						</form>
						<button
							onClick={() => setShowCryptoEmail(false)}
							className="w-full mt-4 p-3 bg-gray-200 hover:bg-gray-300 text-gray-800 text-lg font-semibold rounded-lg transition duration-200"
						>
							← Back
						</button>
					</div>
				)}
			</div>
		</div>
	);
};

export default Checkout;
