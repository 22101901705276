import React from "react";

const Demos = () => {
	const videos = [
		{
			id: "g0LH8mz0VlU",
			title: "Overview of Protocraft AI Features",
			description:
				"An overview of the features in Protocraft AI: chatting, user settings, templates, setting a local LLM, and working with the file browser",
		},
		{
			id: "3ttN_UmYSPo",
			title: "Developing a Software Feature with Protocraft AI",
			description:
				"A demonstration of how to develop a software feature using Protocraft AI, describing the new feature and implementing it using VS Code",
		},
		{
			id: "NDDUplJuKHw",
			title: "Game Emulator Demo",
			description: "A demonstration of how to use Templates and switch between different AI endpoints, sharing the same memory",
		},
	];

	return (
		<div className="container mx-auto p-6">
			<h1 className="text-4xl font-bold text-center mb-8">Protocraft AI: Video Demos</h1>
			<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
				{videos.map((video, index) => (
					<div
						key={video.id}
						className={`bg-gray-100 p-6 rounded-lg shadow-lg ${
							index === videos.length - 1 && videos.length % 2 !== 0 ? "md:col-span-2 md:max-w-[50%] md:mx-auto" : ""
						}`}
					>
						<h2 className="text-2xl font-semibold mb-4">{video.title}</h2>
						<p className="text-lg mb-4">{video.description}</p>
						<div className="relative w-full" style={{ paddingBottom: "56.25%" }}>
							{" "}
							{/* Changed to 56.25% for 16:9 aspect ratio */}
							<iframe
								src={`https://www.youtube.com/embed/${video.id}?rel=0&showinfo=0&controls=1`}
								title={video.title}
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen
								className="absolute top-0 left-0 w-full h-full rounded-lg"
							></iframe>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default Demos;
