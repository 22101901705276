import React from "react";
import ImageWithModal from "../../../common/ImageWithModal";
import { Link } from "react-router-dom";

// Import images (you'll need to add these to your project)
import viewFileImg from "../../../../images/docs/file-browser/view-file.png";
import viewImageImg from "../../../../images/docs/file-browser/view-image.png";
import editFileImg from "../../../../images/docs/file-browser/edit-in-browser.png";
import previewHtmlImg from "../../../../images/docs/file-browser/html-preview.png";

const UsingFiles = () => {
	return (
		<div>
			<h1 className="text-4xl font-bold mb-6">Using Files in the File Browser</h1>

			<p className="mb-4">
				The File Browser in Protocraft allows you to interact with your files directly within the application. You can view file contents, images, and
				videos, edit text and code files, and even preview certain file types like SVG and HTML.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Viewing File Contents</h2>
			<ImageWithModal src={viewFileImg} alt="View File Contents" className="mb-4" />
			<p className="mb-4">To view the contents of a file:</p>
			<ol className="list-decimal list-inside ml-4 mb-4">
				<li>Navigate to the desired file in the File Browser.</li>
				<li>Click on the file name to open it in the file viewer.</li>
				<li>The file's contents will be displayed in the main panel.</li>
			</ol>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Viewing Images and Videos</h2>
			<ImageWithModal src={viewImageImg} alt="View Image" className="mb-4" />
			<p className="mb-4">
				When you select an image or video file in the File Browser, it will be displayed directly in the file viewer. You can zoom in and out of the
				image using the + / - buttons in the file viewer header.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Editing Text and Code Files</h2>
			<ImageWithModal src={editFileImg} alt="Edit File" className="mb-4" />
			<p className="mb-4">To edit a text or code file:</p>
			<ol className="list-decimal list-inside ml-4 mb-4">
				<li>Open the file in the file viewer.</li>
				<li>Click the "Edit" icon (pencil icon) in the top-left corner of the file viewer.</li>
				<li>Make your changes in the editor that appears.</li>
				<li>Click the green "Save" icon to apply your changes, or the red "Cancel" icon to discard them.</li>
			</ol>
			<p className="mb-4">
				<strong>Note:</strong> The file editor includes syntax highlighting for many programming languages, making it easier to edit code files.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Previewing SVG and HTML Files</h2>
			<ImageWithModal src={previewHtmlImg} alt="Preview HTML" className="mb-4" />
			<p className="mb-4">For SVG and HTML files, you have an additional "Preview" option:</p>
			<ol className="list-decimal list-inside ml-4 mb-4">
				<li>Open an SVG or HTML file in the file viewer.</li>
				<li>Click the "Preview" button (world icon) in the top-right corner of the file viewer.</li>
				<li>The file will be rendered as it would appear in a web browser.</li>
				<li>To return to the text view, uncheck the "Preview" icon.</li>
			</ol>
		</div>
	);
};

export default UsingFiles;
