import { useState, useEffect } from "react";

interface ApiResponse<T> {
	data: T | null;
	loading: boolean;
	error: string | null;
	fetchData: () => void;
}

interface FetchOptions extends RequestInit {
	timeout?: number;
}

// Get the base URL from environment variables
if (!process.env.REACT_APP_API_BASE_URL) {
	console.warn("API base URL is not set. Please set REACT_APP_API_BASE_URL in your environment.");
}

export function getBaseUrl() {
	return process.env.REACT_APP_API_BASE_URL || "";
}

const useApi = <T>(endpoint: string, options?: FetchOptions): ApiResponse<T> => {
	const [data, setData] = useState<T | null>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<string | null>(null);

	const fetchData = async () => {
		try {
			const fetchOptions: RequestInit = {
				...options,
			};

			const url = `${getBaseUrl()}${endpoint}`;
			const response = await fetch(url, fetchOptions);

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			const result = await response.json();
			setData(result);
		} catch (err) {
			if (err instanceof Error) {
				setError(err.message || "An error occurred");
			} else {
				setError("An unknown error occurred");
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, [endpoint]);

	return { data, loading, error, fetchData };
};

export default useApi;

export const call = async <T>(
	endpoint: string,
	method: "GET" | "POST" | "PUT" | "DELETE" = "GET",
	data?: any,
	headers?: Record<string, string>,
	options?: FetchOptions,
): Promise<T> => {
	try {
		const fetchOptions: RequestInit = {
			method,
			headers: {
				"Content-Type": "application/json",
				...headers,
			},
			...options,
		};

		if (data && method !== "GET") {
			fetchOptions.body = JSON.stringify(data);
		}

		const url = `${getBaseUrl()}${endpoint}`;
		const response = await fetch(url, fetchOptions);

		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}

		const contentType = response.headers.get("content-type");
		if (contentType && contentType.includes("application/json")) {
			const result = await response.json();
			return result as T;
		} else if (contentType && contentType.includes("application/zip")) {
			const result = await response.blob();
			return result as T;
		}

		const result = await response.text();
		return result as T;
	} catch (err) {
		return Promise.reject(err);
	}
};
