import React from "react";
import { Link } from "react-router-dom";
import googleSearchImg from "../../../../images/docs/settings/api-keys.png";
import ImageWithModal from "../../../common/ImageWithModal";

const SettingUpGoogleSearch = () => {
	const urlForKey = (key: string) => {
		if (key === "googleSearchApiKey") {
			return "https://developers.google.com/custom-search/v1/introduction";
		} else if (key === "googleSearchEngineId") {
			return "https://cse.google.com/cse/create/new";
		}
		return "";
	};

	return (
		<div>
			<h1 className="text-4xl font-bold mb-6">Setting Up Google Search</h1>

			<p>
				Protocraft allows AI LLMs to use Google Search as a tool for gathering information. This page will guide you through the process of setting up
				Google Search integration.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Google Search Setup</h2>
			<p>To use the Google search tool in Protocraft, you need to set up two things:</p>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>
					Google Search API Key -{" "}
					<a href={urlForKey("googleSearchApiKey")} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
						Get API Key
					</a>
				</li>
				<li>
					Google Custom Search Engine ID -{" "}
					<a href={urlForKey("googleSearchEngineId")} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
						Create Search Engine
					</a>
				</li>
			</ul>

			<ImageWithModal src={googleSearchImg} alt="Google Search Settings" />

			<h2 className="text-2xl font-semibold mt-8 mb-4">Steps to Set Up Google Search</h2>
			<ol className="list-decimal list-inside ml-4 mb-4">
				<li>
					Obtain a Google Search API Key:
					<ul className="list-disc list-inside ml-8 mb-2">
						<li>
							Visit the{" "}
							<a href={urlForKey("googleSearchApiKey")} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
								Google Custom Search API page
							</a>
							.
						</li>
						<li>Follow the instructions to create a new project and enable the Custom Search API.</li>
						<li>Create credentials to get your API key.</li>
					</ul>
				</li>
				<li>
					Create a Custom Search Engine:
					<ul className="list-disc list-inside ml-8 mb-2">
						<li>
							Go to the{" "}
							<a href={urlForKey("googleSearchEngineId")} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
								Custom Search Engine creation page
							</a>
							.
						</li>
						<li>Follow the steps to create a new search engine.</li>
						<li>Note down the Search Engine ID provided.</li>
					</ul>
				</li>
				<li>
					Enter the API Key and Search Engine ID in Protocraft:
					<ul className="list-disc list-inside ml-8 mb-2">
						<li>In Protocraft settings, navigate to the "API Keys" section.</li>
						<li>Enter your Google Search API Key in the appropriate field.</li>
						<li>Enter your Custom Search Engine ID in the designated field.</li>
						<li>Save your changes.</li>
					</ul>
				</li>
			</ol>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Using Google Search in AI Interactions</h2>
			<p>
				Once set up, AI models in Protocraft can use Google Search as a tool to gather information when responding to your queries. This allows for more
				up-to-date and comprehensive responses.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Privacy and Usage Notes</h2>
			<p>Be aware that when using the Google Search tool:</p>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>Queries will be sent to Google's servers as part of the search process.</li>
				<li>Usage of the API may incur costs depending on your Google Cloud account setup and usage volume.</li>
				<li>Ensure you comply with Google's terms of service and usage policies.</li>
			</ul>
		</div>
	);
};

export default SettingUpGoogleSearch;
