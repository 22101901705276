import React from "react";
import { Link } from "react-router-dom";
import chatInterfaceImg from "../../../../images/docs/using/chat-interface.png";
import chatHistoryImg from "../../../../images/docs/using/chat-history.png";
import chatOptionsImg from "../../../../images/docs/using/chat-options.png";
import ImageWithModal from "../../../common/ImageWithModal";

const PromptingWithChat = () => {
	return (
		<div>
			<h1 className="text-4xl font-bold mb-6">Prompting with Chat</h1>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Chat Interface</h2>
			<p>The chat interface is where you interact with the AI:</p>
			<ImageWithModal src={chatInterfaceImg} alt="Chat Interface" className="mb-4" />
			<p className="mb-4">
				You can type your messages in the input box at the bottom. Below the prompt input box is the token estimates for the message you are typing, so
				you have a sense of how many tokens the message and all attached files and data will use.
			</p>

			<p className="mb-4">Press Enter to send your message and receive a response from the AI, or use the Send button to send the message.</p>

			<p>
				When tools are enabled, the AI will become more autonomous, and it will execute multiple messages in succession for a single request - this can
				be a very powerful tool, and provides the AI with agency while Protocraft offers guardrails around what the LLM can and can't access or do.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Chat History</h2>
			<p>
				The chat history allows you to review and continue past interactions and initialize new chats. You can also switch between, create and delete
				workspaces from this sidebar.
			</p>
			<ImageWithModal src={chatHistoryImg} alt="Chat History" className="mb-4" />
			<p>You can scroll through your conversation history, which helps maintain context and continuity in your interactions with the AI.</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Chat Options</h2>
			<p>The chat window contains several options to customize your interaction experience:</p>
			<ImageWithModal src={chatOptionsImg} alt="Chat Options" className="mb-4" />
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>
					<strong>Start New Thread:</strong> Begin a fresh conversation with the AI, clearing the previous context and history. This thread is added
					as a new thread attached to the current chat.
				</li>
				<li>
					<strong>Hide Info:</strong> Toggle the visibility of additional information in the chat interface, such as tokens used, LLM chosen, whether
					caching was enabled, and the cost of the prompt (either estimated or exact).
				</li>
				<li>
					<strong>Hide Markdown Styles:</strong> Switch between raw and formatted markdown display in messages.
				</li>
				<li>
					<strong>Hide Tool Cards:</strong> Show or hide the visual representations of tools used by the AI.
				</li>
			</ul>
			<p>
				These options allow you to tailor the chat interface to your preferences, enhancing readability and focus during your interactions with the AI.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Using Templates</h2>
			<p>
				Templates can help structure your prompts for specific tasks. They provide pre-defined formats that can guide your interactions and make them
				more effective. To learn more about using templates, visit the{" "}
				<Link to="/docs/using-templates" className="text-blue-500 hover:underline">
					Using Templates
				</Link>{" "}
				section.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Best Practices for Prompting</h2>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>Be clear and specific in your requests</li>
				<li>Provide context when necessary</li>
				<li>Break down complex tasks into smaller steps</li>
				<li>Experiment with different phrasings if you're not getting the desired results</li>
				<li>Use system messages to set the tone or role of the AI</li>
			</ul>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Next Steps</h2>
			<p>Once you're comfortable with the chat interface, explore these advanced features to enhance your experience:</p>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>
					<Link to="/docs/choosing-llms" className="text-blue-500 hover:underline">
						Choosing LLMs
					</Link>
					: Filters and options for LLMs and their settings.
				</li>
				<li>
					<Link to="/docs/using-tools" className="text-blue-500 hover:underline">
						Using Tools
					</Link>
					: Give the AI access to tools to help it answer questions and complete tasks.
				</li>
				<li>
					<Link to="/docs/setting-initialization-rules" className="text-blue-500 hover:underline">
						Setting Initialization Rules
					</Link>
					: Customize the AI's behavior at the start of each conversation.
				</li>
			</ul>
			<p>These sections will guide you through optimizing your interactions with the AI and unlocking its full potential.</p>
		</div>
	);
};

export default PromptingWithChat;
