import React from "react";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";
import "./index.css";
import App from "./app/App";

if (process.env.NODE_ENV === "production") {
	ReactGA.initialize("G-ZX7PQG3BYB");
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
);
