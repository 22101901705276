import React from "react";
import ImageWithModal from "../../../common/ImageWithModal";
import templatesOverviewImg from "../../../../images/docs/using/setup-template.png";
import templateUseImg from "../../../../images/docs/using/prompt-with-template.png";
import templateExampleImg from "../../../../images/docs/using/prompt-with-template-result.png";
import templateExampleExpandedImg from "../../../../images/docs/using/prompt-with-template-result-expanded.png";

const UsingTemplates = () => {
	return (
		<div>
			<h1 className="text-4xl font-bold mb-6">Using Templates</h1>

			<p className="mb-4">
				Templates in Protocraft are pre-defined prompts that help structure your interactions with the AI. They can be used to set a specific tone,
				role, or context for the AI's responses, making it easier to get consistent and targeted results. They can also just be used for commonly-used
				prompts and setups.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Creating and Editing Templates</h2>
			<ImageWithModal src={templatesOverviewImg} alt="Template Edit" className="mb-4" />
			<p className="mb-4">Templates are managed in the Templates tab at the top of the program. To create a new template or edit an existing one:</p>
			<ol className="list-decimal list-inside ml-4 mb-4">
				<li>Start writing your prompt as you normally would, or click on "View" under an existing prompt to edit it:</li>
				<li>Enter a name for your template in the "Template Name" field.</li>
				<li>In the large text area, write the prompt that will guide the AI's behavior.</li>
				<li>Below the main prompt, you can add a "User Prompt" that will be shown to the user when they select this template.</li>
				<li>Click "Save Changes" to store your template.</li>
			</ol>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Using Templates in Chat</h2>
			<ImageWithModal src={templateUseImg} alt="Template Use" className="mb-4" />
			<p className="mb-4">To use a template in your chat:</p>
			<ol className="list-decimal list-inside ml-4 mb-4">
				<li>In the chat interface, locate the "Template" dropdown menu at the bottom of the screen.</li>
				<li>Select the desired template from the dropdown list.</li>
				<li>The user prompt associated with the template will appear in the prompt input box.</li>
				<li>Provide any additional information you want to include in the prompt input box.</li>
				<li>Press Enter or click Send to submit your message.</li>
			</ol>
			<p className="mb-4">The AI will recieve the template as a user prompt followed by your additional prompt.</p>
			<ImageWithModal src={templateExampleImg} alt="Template Use" className="mb-4" />
			<p className="mb-4">You can expand the template box in the chat window to see the full prompt that will be sent to the AI.</p>
			<ImageWithModal src={templateExampleExpandedImg} alt="Template Use" className="mb-4" />

			<h2 className="text-2xl font-semibold mt-8 mb-4">Benefits of Using Templates</h2>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>Consistency: Templates ensure that the AI maintains a specific persona or approach across multiple interactions.</li>
				<li>Efficiency: Quickly set up complex prompts without having to retype them for each conversation.</li>
				<li>Customization: Create templates tailored to your specific needs or use cases.</li>
				<li>Collaboration: Share effective prompts with team members or the community.</li>
			</ul>

			<p className="mt-4">
				By mastering the use of templates, you can significantly enhance your productivity and the quality of your interactions with the AI in
				Protocraft.
			</p>
		</div>
	);
};

export default UsingTemplates;
