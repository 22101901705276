import React from "react";
import ImageWithModal from "../../../common/ImageWithModal";
import workspaceIndexer from "../../../../images/docs/workspace-indexer/workspace-indexer.png";
import workspaceIndexerAddFile from "../../../../images/docs/workspace-indexer/workspace-indexer-add-file.png";
import workspaceIndexerPrompt from "../../../../images/docs/workspace-indexer/workspace-indexer-prompt.png";

const WorkspaceIndexing: React.FC = () => {
	return (
		<div>
			<h1 className="text-4xl font-bold mb-6">Workspace Indexing</h1>

			<p className="mb-4">
				Workspace indexing in Protocraft is a powerful feature that creates a RAG (Retrieval-Augmented Generation) database and an NLP (Natural Language
				Processing) index of your entire workspace. This enables intelligent file suggestions and enhances your prompting experience.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Workspace Indexer Overview</h2>
			<ImageWithModal src={workspaceIndexer} alt="Workspace Indexer" className="mb-4" />
			<p className="mb-4">
				The Workspace Indexer interface provides an overview of your current workspace and indexing status. Here are the key components:
			</p>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>Current Workspace: Displays the name and ID of your active workspace.</li>
				<li>Index Status: Indicates whether an index exists for the current workspace.</li>
				<li>Embedding Model: Shows the current model used for text embedding.</li>
				<li>Action Buttons: Options to update, query, or clear the index.</li>
			</ul>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Using the Workspace Indexer</h2>
			<p className="mb-4">To use the Workspace Indexer effectively:</p>
			<ol className="list-decimal list-inside ml-4 mb-4">
				<li>Ensure your desired workspace is selected.</li>
				<li>Click "Create Workspace Index" to create or refresh the index for your workspace.</li>
				<li>Use "Query Index" to test the indexing and search for specific content.</li>
				<li>"Clear Index" removes the existing index if you want to start fresh or stop using the feature.</li>
			</ol>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Intelligent File Suggestions</h2>
			<ImageWithModal src={workspaceIndexerAddFile} alt="Add File to Workspace Indexer" className="mb-4 shadow-2xl w-[60%]" />
			<p className="mb-4">One of the key benefits of workspace indexing is intelligent file suggestions while writing prompts:</p>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>As you type your prompt, relevant files from your workspace will appear as suggestions.</li>
				<li>You can view these suggested files directly from the prompt interface.</li>
				<li>Easily add relevant files to your prompt by clicking on them.</li>
			</ul>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Enhanced Prompting Experience</h2>
			<ImageWithModal src={workspaceIndexerPrompt} alt="Workspace Indexer Prompt" className="mb-4" />
			<p className="mb-4">Workspace indexing enhances your prompting experience in several ways:</p>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>Contextual Awareness: The AI gains a better understanding of your project context.</li>
				<li>Efficient File Access: Quickly incorporate relevant files into your prompts.</li>
				<li>Improved Responses: The AI can provide more accurate and project-specific answers.</li>
			</ul>

			<p className="mt-4">
				By leveraging workspace indexing, you can significantly improve your productivity and the quality of AI-assisted development in Protocraft. The
				feature ensures that the AI has a comprehensive understanding of your project, leading to more relevant and accurate responses.
			</p>
		</div>
	);
};

export default WorkspaceIndexing;
