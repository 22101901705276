import React from "react";
import { Link } from "react-router-dom";
import welcomeMsgImg from "../../../../images/docs/setup/welcome-msg.png";
import licensePopupImg from "../../../../images/docs/setup/license-popup.png";
import ImageWithModal from "../../../common/ImageWithModal";

const StartingUpProtocraft = () => {
	return (
		<div>
			<h1 className="text-4xl font-bold mb-6">Starting up Protocraft</h1>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Welcome Message</h2>
			<p>When you first launch Protocraft, you'll be greeted with a welcome message:</p>
			<ImageWithModal src={welcomeMsgImg} alt="Welcome Message" />
			<p>
				This message informs you that before you can start chatting with an AI, you need to configure your API settings. You have two options to get
				started:
			</p>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>Add an API Key</li>
				<li>Set up a custom local API & model</li>
			</ul>

			<h2 className="text-2xl font-semibold mt-8 mb-4">License Popup</h2>
			<p>After closing the welcome message, you'll see a license popup:</p>
			<ImageWithModal src={licensePopupImg} alt="License Popup" />
			<p>
				This popup provides information about your Protocraft license. If you have a license key, you can enter it here. Otherwise, you can close the
				popup to continue with the evaluation version. For more information about licensing, please refer to the{" "}
				<Link to="/docs/licensing-and-pricing" className="text-blue-500 hover:underline">
					Licensing and Pricing
				</Link>{" "}
				section.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Next Steps</h2>
			<p>After closing the license popup, you should set up at least one of the following:</p>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>An API key for an external service</li>
				<li>A local Large Language Model (LLM)</li>
			</ul>
			<p>The next two pages in the setup documentation will guide you through these processes:</p>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>
					<Link to="/docs/setting-up-api-keys" className="text-blue-500 hover:underline">
						Setting Up API Keys
					</Link>
				</li>
				<li>
					<Link to="/docs/setting-up-local-llm" className="text-blue-500 hover:underline">
						Setting Up a Local LLM
					</Link>
				</li>
			</ul>
			<p>Choose the option that best suits your needs and follow the instructions to get Protocraft fully configured and ready to use.</p>
		</div>
	);
};

export default StartingUpProtocraft;
