import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Home from "../components/pages/Home";
import FAQ from "../components/pages/FAQ";
import Purchase from "../components/pages/Purchase";
import PurchaseSuccess from "../components/pages/PurchaseSuccess";
import DownloadLinks from "../components/pages/Download";
import Account from "../components/pages/Account";
import PrivacyPolicy from "../components/pages/PrivacyPolicy";
import EULA from "../components/pages/EULA";
import Demos from "../components/pages/Demos";
import Documentation from "../components/pages/docs/Documentation";
import { AuthProvider } from "../providers/AuthProvider";

const ScrollToTop = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
};

const AppContent = () => {
	const location = useLocation();

	useEffect(() => {
		if (process.env.NODE_ENV === "production") {
			ReactGA.send({
				hitType: "pageview",
				page: location.pathname,
				title: document.title,
			});
		}
	}, [location]);

	return (
		<div className="min-h-screen min-w-screen max-w-screen max-h-full bg-gray-100 dark:bg-gray-900 transition-colors duration-200 flex flex-col">
			<ScrollToTop />
			<Header />
			<main className="relative h-full w-full bg-gray-100 dark:bg-gray-900">
				<Routes>
					<Route path="/" element={<Home />} />
				</Routes>
			</main>
			<main className="flex-1 lg:p-4 flex flex-row items-stretch justify-center max-md:flex-col relative h-full w-full bg-gray-100 dark:bg-gray-900">
				<Routes>
					<Route path="/faq" element={<FAQ />} />
					<Route path="/purchase" element={<Purchase />} />
					<Route path="/purchase-success" element={<PurchaseSuccess />} />
					<Route path="/downloads" element={<DownloadLinks />} />
					<Route path="/account" element={<Account />} />
					<Route path="/privacy-policy" element={<PrivacyPolicy />} />
					<Route path="/eula" element={<EULA />} />
					<Route path="/demos" element={<Demos />} />
					<Route path="/docs/*" element={<Documentation />} />
				</Routes>
			</main>
			<Footer />
		</div>
	);
};

const App = () => {
	return (
		<AuthProvider>
			<Router>
				<AppContent />
			</Router>
		</AuthProvider>
	);
};

export default App;
