import React from "react";
import { Link } from "react-router-dom";
import leftHeader from "../../../../images/docs/using/left-header.png";
import rightHeader from "../../../../images/docs/using/right-header.png";
import ImageWithModal from "../../../common/ImageWithModal";

const NavigationAppBar: React.FC = () => {
	return (
		<div>
			<h1 className="text-4xl font-bold mb-6">Navigation & App Bar</h1>

			<p>The Navigation and App Bar provide direct access to Protocraft's various features and tools.</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Left Header Overview</h2>
			<p>The left header contains the main navigation elements for Protocraft. Let's break down each component:</p>

			<ImageWithModal src={leftHeader} alt="Left Header" className="w-[40%]" />

			<h2 className="text-2xl font-semibold mt-8 mb-4">1. Sidebar Toggles</h2>
			<p>The sidebar toggles allow you to show or hide the directory browser and your chat history:</p>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>
					<strong>Directory Browser:</strong> Toggle the directory viewer sidebar, allowing you to browse your project files.
				</li>
				<li>
					<strong>Chat History:</strong> Toggle the chat history sidebar, providing access to past conversations.
				</li>
			</ul>

			<h2 className="text-2xl font-semibold mt-8 mb-4">2. Chat Interface</h2>
			<p>The primary feature of Protocraft is the chat interface:</p>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>
					<strong>Chat:</strong> The main interface for interacting with the AI. This is where you'll spend most of your time communicating and
					working on your projects.
				</li>
			</ul>

			<h2 className="text-2xl font-semibold mt-8 mb-4">3. Features</h2>
			<p>The secondary features provide access to additional tools and settings:</p>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>
					<strong>File Viewer & Editor:</strong> View and edit files directly within Protocraft.
				</li>
				<li>
					<strong>Templates:</strong> Access and manage prompt templates for quicker interactions.
				</li>
				<li>
					<strong>Retrieval Augmented Generation (RAG):</strong> Enhance AI responses with information from your project files.
				</li>
				<li>
					<strong>Image Generation:</strong> Generate images using various image models, including running local models.
				</li>
				<li>
					<strong>Workspace:</strong> Manage your current workspace settings and configuration.
				</li>
				<li>
					<strong>Workspace Indexer:</strong> Index your workspace for rapid file suggestions within the chat interface.
				</li>
				<li>
					<strong>Settings:</strong> Configure Protocraft, set up API keys, and manage your local models.
				</li>
			</ul>

			<h2 className="text-2xl font-semibold mt-8 mb-4">4. Indexer Status</h2>
			<p>
				When the workspace indexer is active for a workspace, and is actively updating, you'll see an "Updating index..." indicator with a spinning
				loader. This lets you know that Protocraft is indexing any changes in your workspace files for accurate file suggestions.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Right Header</h2>
			<ImageWithModal src={rightHeader} alt="Right Header" className="w-[20%]" />
			<p>The right header provides information about Protocraft, your license, and the ability to toggle between light and dark themes.</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Using the Navigation Bar</h2>
			<p>Here are some tips for using the navigation bar effectively:</p>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>Most of the features interact with the chat interface and the directory browser, such as drag and drop file and image support.</li>
				<li>All of the features can be used standalone (with the chat interface closed), or with the chat interface open.</li>
			</ul>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Next Steps</h2>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>
					<Link to="/docs/prompting-with-chat" className="text-blue-500 hover:underline">
						Learn how to use the Chat interface
					</Link>
				</li>
				<li>
					<Link to="/docs/using-templates" className="text-blue-500 hover:underline">
						Discover how to use Templates
					</Link>
				</li>
				<li>
					<Link to="/docs/retrieval-augmented-generation" className="text-blue-500 hover:underline">
						Explore Retrieval Augmented Generation
					</Link>
				</li>
			</ul>
		</div>
	);
};

export default NavigationAppBar;
