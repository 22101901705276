import React from "react";
import ImageWithModal from "../../../common/ImageWithModal";
import ragOverview from "../../../../images/docs/rag/rag.png";
import ragCreate from "../../../../images/docs/rag/rag-create.png";
import ragDnd from "../../../../images/docs/rag/rag-dnd.png";
import ragQuery from "../../../../images/docs/rag/rag-query.png";
import ragSettings from "../../../../images/docs/rag/rag-settings.png";
import ragChat from "../../../../images/docs/rag/rag-chat.png";
import ragChatExpand from "../../../../images/docs/rag/rag-chat-expand.png";

const RetrievalAugmentedGeneration: React.FC = () => {
	return (
		<div>
			<h1 className="text-4xl font-bold mb-6">Retrieval Augmented Generation</h1>

			<p className="mb-4">
				Retrieval Augmented Generation (RAG) is a powerful feature in Protocraft that enhances the AI's ability to provide accurate and context-aware
				responses by leveraging information from a custom knowledge base.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Overview of RAG</h2>
			<ImageWithModal src={ragOverview} alt="RAG Overview" className="mb-4" />
			<p className="mb-4">
				RAG allows you to create a searchable database of your documents, code, or any text-based information. When you ask a question, the system
				retrieves relevant information from this database to augment the AI's knowledge, resulting in more informed and tailored responses.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Creating a RAG Database</h2>
			<ImageWithModal src={ragCreate} alt="Creating a RAG Database" className="mb-4" />
			<p className="mb-4">To create a new RAG database:</p>
			<ol className="list-decimal list-inside ml-4 mb-4">
				<li>Click on the "Create New" button in the RAG section.</li>
				<li>Enter a name for your database.</li>
				<li>Select the embedding model you want to use.</li>
				<li>Choose the files or folders you want to include in your database.</li>
			</ol>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Adding Files to Your RAG Database</h2>
			<ImageWithModal src={ragDnd} alt="Adding Files to RAG Database" className="mb-4 shadow-2xl" />
			<p className="mb-4">
				You can easily add files to your RAG database by dragging and dropping them into the designated area. This allows you to quickly expand your
				knowledge base with new information.
			</p>
			<p className="mb-4">You can drag and drop files from your local computer / desktop, and from the directory browser in the sidebar.</p>
			<p className="mb-4">Currently the RAG databases can only index and support text files (markdown, txt, code, etc) - PDFs are not yet supported.</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Querying Your RAG Database</h2>
			<ImageWithModal src={ragQuery} alt="Querying RAG Database" className="mb-4" />
			<p className="mb-4">To test the kind of results that your RAG database will return, you can query your RAG database:</p>
			<ol className="list-decimal list-inside ml-4 mb-4">
				<li>Select the RAG database you want to use.</li>
				<li>Enter your query in the search box.</li>
				<li>Click "Run Query" to see the most relevant results from your database.</li>
				<li>Click on the Eye icon to view the contents of that RAG result.</li>
			</ol>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Adjusting RAG Settings</h2>
			<ImageWithModal src={ragSettings} alt="RAG Settings" className="mb-4" />
			<p className="mb-4">
				You can fine-tune your RAG database settings to optimize its performance. This includes adjusting the number of top results to include in the
				AI's context, which can help balance between providing comprehensive information and maintaining relevance.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Using RAG in Chat</h2>
			<ImageWithModal src={ragChat} alt="Using RAG in Chat" className="mb-4" />
			<p className="mb-4">
				When you have a RAG database selected, your chat interactions will automatically be augmented with relevant information from your database. This
				allows the AI to provide more accurate and contextually appropriate responses.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Expanding RAG Results</h2>
			<ImageWithModal src={ragChatExpand} alt="Expanding RAG Results" className="mb-4" />
			<p className="mb-4">
				You can expand the RAG results in the chat interface to see which parts of your database were used to inform the AI's response. This
				transparency helps you understand the basis of the AI's knowledge and allows you to verify the sources of information.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Benefits of Using RAG</h2>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>Enhanced Accuracy: Responses are based on your specific knowledge base.</li>
				<li>Contextual Awareness: The AI can reference your documents and code for more relevant answers.</li>
				<li>Customization: Tailor the AI's knowledge to your specific domain or project.</li>
				<li>Up-to-date Information: Easily update your knowledge base with new information.</li>
			</ul>

			<p className="mt-4">
				By effectively using Retrieval Augmented Generation, you can significantly improve the quality and relevance of AI-generated responses in
				Protocraft, making it an invaluable tool for your development workflow.
			</p>
		</div>
	);
};

export default RetrievalAugmentedGeneration;
