import React from "react";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
	return (
		<div className="container mx-auto p-6">
			<div className="flex flex-col space-y-4 items-left justify-start">
				<h4>Protocraft AI Privacy Policy</h4>
				<p>Last Updated: September 13, 2024</p>

				<p>
					Cybourgeoisie LLC ("Protocraft AI", "we", "us", or "our") is committed to protecting your privacy and complying with all applicable Data
					Protection rules and regulations. We have prepared this Privacy Policy to describe our practices regarding Personal Data (as defined below)
					we collect, use, and share in connection with the Protocraft AI website and software application provided. Please also refer to the{" "}
					<a href="/eula" target="_blank" className="underline">
						End-User License Agreement
					</a>{" "}
					that comes with your software license.
				</p>

				<ol className="space-y-4">
					<li className="space-y-4">
						<b>Types of Data We Collect</b>. On our website, we collect Personal Data and Anonymous Data. "Personal Data" means data that allows
						someone to identify you individually, specifically your email address. "Anonymous Data" means data, including aggregated and
						de-identified data, that is not associated with or linked to your Personal Data; Anonymous Data does not, by itself, permit the
						identification of individual persons. We collect Personal Data and Anonymous Data as described below.
						<ol className="space-y-4">
							<li className="space-y-4">
								<b>Information You Provide Us</b>.
								<ol className="space-y-4">
									<li>
										When you use our Product, update your account profile, or contact us, we may collect Personal Data from you,
										specifically your email address.
									</li>
									<li>
										If you provide us with feedback or contact us, we will collect your email address, as well as any other content included
										in the message.
										<br />
									</li>
									<li>
										<u>Google Analytics.</u> On our website, we use Google Analytics, which may gather certain information automatically and
										store it in log files. This information includes IP addresses, browser type, Internet service provider ("ISP")
										referring/exit pages, operating system, date/time stamps, and clickstream data. We use this information to analyze
										trends and better understand how to market Protocraft AI.
									</li>
									<li>
										<u>Software Use.</u> We do not collect personal information from you when you use the Protocraft AI software
										application. The software application will make API calls to our servers, but only in the cases to provide updated
										software and configurations, such as validating the license key or pulling the latest available AI models for each
										supported provider, and will never collect or contain personal information, private information, or anonymous aggregated
										information. None of your chats, files, or personal information are ever transferred to our servers.
									</li>
								</ol>
							</li>
						</ol>
					</li>
					<li className="space-y-4">
						<b>
							<u>Use of Your Personal Data</u>
						</b>

						<ol className="space-y-4">
							<li className="space-y-4">
								We process minimal Personal Data from you to run our business, provide our software applications, and improve our software.
								Specifically, we may use your Personal Data to:
								<ol className="space-y-2 pt-4 px-4">
									<li>Facilitate the creation of and secure your account;</li>
									<li>Identify you as a user in our system;</li>
									<li>Provide you with licenses to our software applications;</li>
									<li>
										Improve the administration of our products and quality of experience when you interact with our website or products;
									</li>
									<li>Provide customer support and respond to your requests and inquiries;</li>
									<li>Investigate and address conduct that may violate our End-User License Agreement;</li>
									<li>
										Detect, prevent, and address fraud and/or security breaches, violations of our terms or policies, and/or other harmful
										or unlawful activity;
									</li>
									<li>
										Send you a emails to allow you to log in, obtain your software license keys, and receive notifications about software
										updates;
									</li>
									<li>Send you administrative notifications, such as security, support, and maintenance advisories;</li>
									<li>
										Respond to your inquiries;
										<br />
									</li>
									<li>
										Comply with applicable laws and governmental regulations, cooperate with investigations by law enforcement or other
										authorities of suspected violations of law, and/or to pursue or defend against legal threats and/or claims; and
										<br />
									</li>
									<li>Act in any other way we may describe when you provide the Personal Data.</li>
								</ol>
							</li>
							<li>
								We may create Anonymous Data records from Personal Data. We use this Anonymous Data to analyze request and usage patterns so
								that we may improve our website or applications and enhance website navigation. We reserve the right to use Anonymous Data when
								permissible.
							</li>
						</ol>
					</li>
					<li>
						<b>Disclosure of Your Personal Data</b>. We disclose your Personal Data only in the specific cases as described below and as described
						elsewhere in this Privacy Policy.
					</li>
					<li>
						<b>
							<u>Corporate Restructuring</u>
						</b>
						<u>.</u> We may share some or all of your Personal Data in connection with or during negotiation of any merger, financing, acquisition,
						or dissolution transaction or proceeding involving sale, transfer, divestiture, or disclosure of all or a portion of our business or
						assets. If another company acquires our company, business, or assets, that company will possess the Personal Data collected by us and
						will assume the rights and obligations regarding your Personal Data as described in this Privacy Policy.
					</li>
					<li>
						<b>
							<u>Legal Rights</u>
						</b>{" "}
						Regardless of any choices you make regarding your Personal Data (as described below), Cybourgeoisie LLC may disclose Personal Data: (a)
						in connection with any legal investigation; (b) to comply with relevant laws or to respond to subpoenas, warrants, or other legal
						process served on Cybourgeoisie LLC; (c) to protect or defend the rights or property of Cybourgeoisie LLC or users of the website or
						applications; and/or (d) to investigate or assist in preventing any violation or potential violation of the law, this Privacy Policy, or
						our Terms of Use.
					</li>
					<li>
						<b>User Rights </b>Depending on jurisdiction, users who provide Personal Data (whether via active or auto consent) may have certain
						statutory and/or regulatory rights to that Personal Data, including but not limited to: (a) Access to the Personal Data, (b) Right to
						request deletion of some or all of the Personal Data collected, and (c) Right to opt-out of data selling. For more specific information
						refer to subsequent Section Seven.
					</li>
					<li>
						<b>Your Choices Regarding Information</b>. You have several choices regarding the use of information on our website or applications:
						<ol className="space-y-4 pt-4">
							<li>
								<b>Email Communications</b>. As long as you have provided opt-in consent, we may periodically send you emails that directly
								promote the use of our website or applications or third parties' goods and services. When you receive newsletters or promotional
								communications from us, you may indicate a preference to stop receiving these communications from us by following the
								unsubscribe instructions provided in the email you receive or through the Notifications preferences in your Settings page.
								Despite these preferences, we may send you occasional transactional service-related informational communications.
							</li>
							<li>
								If you decide at any time that you no longer wish to accept Cookies from our website as discussed above you can instruct your
								browser to stop accepting Cookies.
							</li>
						</ol>
					</li>
					<li>
						<b>Data Access and Control</b>. You may also have certain additional rights:
						<ol className="space-y-4 pt-4">
							<li>
								If you are a user in the European Economic Area or United Kingdom, you have certain rights under the respective European and UK
								General Data Protection Regulations ("GDPR"). These include the right to (i) request access and obtain a copy of your personal
								data; (ii) request rectification or erasure; (iii) object to or restrict the processing of your personal data; and (iv) request
								portability of your personal data. Additionally, if we have collected and processed your personal data with your consent, you
								have the right to withdraw your consent at any time. To contact us concerning your rights please email to:{" "}
								<a className="underline" href="mailto:support@protocraft.ai">
									support@protocraft.ai
								</a>
								.
							</li>
							<li>
								If you are a California resident, you have certain rights under the California Consumer Privacy Act ("CCPA"). These include the
								right to (i) request access to, details regarding, and a copy of the personal information we have collected about you and/or
								shared with third parties; (ii) request deletion of the personal information that we have collected about you; and (iii) the
								right to opt-out of sale of your personal information. As the terms are defined under the CCPA, we do not* "sell" your "personal
								information. To contact us concerning your rights please email to:{" "}
								<a className="underline" href="mailto:support@protocraft.ai">
									support@protocraft.ai
								</a>
								.<br />
							</li>
							<li>
								If you wish to exercise your rights under the GDPR, CCPA, or other applicable data protection or privacy laws, please contact
								our Data Protection Officer by using the "Submit a request" link{" "}
								<a className="underline" href="mailto:support@protocraft.ai">
									<u>Request</u>
								</a>{" "}
								or at the address provided at the bottom of this document, specify your request, and reference the applicable law or regulation.
								We may ask you to verify your identity, or ask for more information about your request. We will consider and act upon any above
								request in accordance with applicable law. We will not discriminate against you for exercising any of these rights.
								<br />
							</li>
						</ol>
					</li>
					<li>
						<b>Data Retention</b>. We may retain your Personal Data as long as you continue to use the website or applications, have an account with
						us, or for as long as is necessary to fulfill the purposes outlined in this Privacy Policy. We may continue to retain your Personal Data
						even after you deactivate your account and/or cease to use the website or applications if such retention is reasonably necessary to
						comply with our legal obligations, to resolve disputes, prevent fraud and abuse, enforce our Terms of Use or other agreements, and/or
						protect our legitimate interests. Where your Personal Data is no longer required for these purposes, we will delete it.
						<br />
					</li>
					<li>
						<b>Data Protection</b>. We care about the security of your information and use physical, administrative, and technological safeguards
						and best practices to preserve the integrity and security of information collected through our website or applications. However, no
						security system is impenetrable and we cannot guarantee the security of our systems. In the event that any information under our custody
						and control is compromised as a result of a breach of security, we will take steps to investigate and remediate the situation and, in
						accordance with applicable laws and regulations, notify those individuals whose information may have been compromised.
						<br />
					</li>
					<li>
						<span>You are responsible for ensuring your Log-in information is kept secure and private.</span>
					</li>
				</ol>
				<li>
					<b>Minors</b>. We do not intentionally gather Personal Data from minors who visit our site. If a minor submits Personal Data to Protocraft
					AI and we learn that the Personal Data is the information of a minor, we will attempt to delete the information as soon as possible. If you
					believe that we might have any Personal Data from a minor, please contact our Data Protection Officer by emailing{" "}
					<a className="underline" href="mailto:support@protocraft.ai" target="_self">
						support@protocraft.ai
					</a>{" "}
					or at the address indicated at the bottom of this document.
				</li>
				<li>
					<b>Users Outside of the United States</b>. If you are a non-U.S. user of the website or applications, by visiting the website or
					applications and providing us with data, you acknowledge and agree that your Personal Data may be processed for the purposes identified
					herein. In addition, your Personal Data may be processed in the country in which it was collected and in other countries, including the
					United States, where laws regarding processing of Personal Data may be less stringent than the laws in your country. By providing your
					Personal Data, you consent to such transfer.
				</li>
				<li>
					<b>Changes to This Privacy Policy</b>. This Privacy Policy may be updated from time to time for any reason. The date the Privacy Policy was
					last revised is identified at the beginning of this Privacy Policy. You are responsible for periodically visiting our website or
					applications and this Privacy Policy to check for any changes.
				</li>
				<li>
					<b>Questions; Contacting Protocraft AI; Reporting Violations</b>. If you have any questions or concerns or complaints about our Privacy
					Policy or our data collection or processing practices, or if you want to report any security violations to us, please contact our Data
					Protection Officer at{" "}
					<a className="underline" href="mailto:support@protocraft.ai">
						support@protocraft.ai
					</a>{" "}
					or at the following address: 2595 Interstate Drive Suite 103, Harrisburg PA, 17110.
				</li>
			</div>
			<div className="mt-8 text-center">
				<Link to="/" className="text-blue-600 hover:text-blue-800 transition duration-200">
					← Back to Home
				</Link>
			</div>
		</div>
	);
};

export default PrivacyPolicy;
