import React from "react";
import ImageWithModal from "../../../common/ImageWithModal";
import imageGen from "../../../../images/docs/image-gen/image-gen.png";
import imageGenFull from "../../../../images/docs/image-gen/image-gen-full.png";
import imageGenDnd from "../../../../images/docs/image-gen/image-gen-dnd.png";
import imageGenView from "../../../../images/docs/image-gen/image-gen-view.png";
import imageGenViewMultiselect from "../../../../images/docs/image-gen/image-gen-view-multiselect.png";
import imageGenVar from "../../../../images/docs/image-gen/image-gen-var.png";

const GeneratingImages: React.FC = () => {
	return (
		<div>
			<h1 className="text-4xl font-bold mb-6">Generating Images</h1>

			<p className="mb-4">
				Protocraft offers powerful image generation capabilities, allowing you to create, edit, and manage AI-generated images directly within the
				application. This guide will walk you through the process of generating images and using the various features available.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Image Generation Overview</h2>
			<ImageWithModal src={imageGen} alt="Image Generation Overview" className="mb-4" />
			<p className="mb-4">
				The image generation interface in Protocraft provides a user-friendly way to create AI-generated images. Here's an overview of the main
				components:
			</p>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>Prompt input: Enter your text description for the image you want to generate.</li>
				<li>Provider selection: Choose from available AI providers for image generation.</li>
				<li>Model selection: Select the specific AI model to use for generation.</li>
				<li>Size selection: Choose the desired dimensions for your generated image.</li>
				<li>Generate button: Click to create your image based on the provided prompt and settings.</li>
			</ul>
			<p className="mb-4">
				The interface will change depending on the provider and model you select - there are additional settings available for Hugging Face and
				AUTOMATIC1111.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Image Viewing and Selection</h2>
			<ImageWithModal src={imageGenView} alt="Image Generation View" className="mb-4" />
			<p className="mb-4">The image viewing interface allows you to:</p>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>View your generated and uploaded images in a grid layout.</li>
				<li>Click on an image to view it in full size.</li>
				<li>Access additional options for each image, such as downloading or deleting.</li>
			</ul>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Drag and Drop Functionality</h2>
			<ImageWithModal src={imageGenDnd} alt="Drag and Drop Functionality" className="mb-4" />
			<p className="mb-4">Protocraft supports drag and drop functionality for easy image management:</p>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>Drag and drop images that were created in Protocraft directly into the directory browser.</li>
				<li>Use these uploaded images as reference or starting points for new generations.</li>
				<li>Easily organize and manage your generated and uploaded images within the application.</li>
			</ul>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Using Your Images</h2>
			<ImageWithModal src={imageGenFull} alt="Using Your Images" className="mb-4" />
			<p className="mb-4">You can use your generated images in the following ways:</p>
			<ol className="list-decimal list-inside ml-4 mb-4">
				<li>Add images directly to your project by dragging them into the directory browser.</li>
				<li>Add images to your chat context by clicking the "Add To Prompt" button.</li>
				<li>Use the image as an init image for another image generation.</li>
			</ol>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Multi-select and Batch Operations</h2>
			<ImageWithModal src={imageGenViewMultiselect} alt="Image Generation View Multiselect" className="mb-4" />
			<p className="mb-4">Protocraft offers multi-select capabilities for efficient image management:</p>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>Select multiple images by clicking the checkbox in the corner of each image.</li>
				<li>Perform batch operations on selected images, such as deleting or downloading multiple images at once.</li>
				<li>Use the "Mass Edit" feature to apply changes to multiple images simultaneously.</li>
			</ul>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Creating Image Variations & In-Painting</h2>
			<ImageWithModal src={imageGenVar} alt="Image Generation Variations" className="mb-4" />
			<p className="mb-4">Protocraft allows you to create variations of existing images and use masks for in-painting:</p>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>Select an existing image as a starting point.</li>
				<li>Use the "Create Variation" option to generate new images based on the selected image.</li>
				<li>Adjust settings like strength and guidance scale to control how closely the variations resemble the original.</li>
				<li>Generate multiple variations to explore different interpretations of the original image.</li>
				<li>Use the "In-Painting" option to modify specific parts of an image by providing a mask of the area to be changed.</li>
			</ul>

			<p className="mt-4">
				By mastering these image generation features, you can unleash your creativity and produce stunning AI-generated images directly within
				Protocraft. Experiment with different prompts, settings, and variations to achieve the perfect results for your projects.
			</p>
		</div>
	);
};

export default GeneratingImages;
