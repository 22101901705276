import React from "react";
import ImageWithModal from "../../../common/ImageWithModal";
import imageEditor from "../../../../images/docs/image-gen/image-editor.png";
import imageEditorModal from "../../../../images/docs/image-gen/image-editor-modal.png";

const EditingImages: React.FC = () => {
	return (
		<div>
			<h1 className="text-4xl font-bold mb-6">Editing Images</h1>

			<p className="mb-4">
				Protocraft offers powerful image editing capabilities, allowing you to modify images directly within the application. This guide will walk you
				through the process of editing images using the built-in editor and its various features.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Image Editing in File Viewer</h2>
			<ImageWithModal src={imageEditor} alt="Image Editor in File Viewer" className="mb-4" />
			<p className="mb-4">
				The image editor is integrated into the file viewer, allowing you to edit images directly from your project files. Here's an overview of the
				main components:
			</p>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>Toolbar: Contains various editing tools and options.</li>
				<li>Canvas: The main area where you can edit your image.</li>
				<li>Layer panel: Manage different layers of your image.</li>
				<li>Properties panel: Adjust settings for the selected tool or layer.</li>
			</ul>
			<p className="mb-4">To edit an image in the file viewer:</p>
			<ol className="list-decimal list-inside ml-4 mb-4">
				<li>Open the image file in the file viewer.</li>
				<li>Click on the "Edit" button in the toolbar to enter editing mode.</li>
				<li>Use the various tools and options to modify your image.</li>
				<li>Save your changes when finished.</li>
			</ol>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Image Editor Modal for Image Generation</h2>
			<ImageWithModal src={imageEditorModal} alt="Image Editor Modal" className="mb-4" />
			<p className="mb-4">
				The image editor can also be accessed through a modal window, specifically for creating or editing init images and mask images used in image
				generation. This modal provides additional features tailored for image generation tasks.
			</p>
			<p className="mb-4">To use the image editor modal for image generation:</p>
			<ol className="list-decimal list-inside ml-4 mb-4">
				<li>In the image generation interface, click on "Edit Init Image" or "Edit Mask".</li>
				<li>The image editor modal will open, allowing you to create or modify your image.</li>
				<li>Use the brush tools to paint or erase areas of your init image or mask.</li>
				<li>Adjust brush size, opacity, and other settings in the properties panel.</li>
				<li>Use layers to organize different elements of your image.</li>
				<li>Click "Apply" to use the edited image in your generation process.</li>
			</ol>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Key Features of the Image Editor</h2>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>Brush and eraser tools with adjustable size and opacity</li>
				<li>Color picker for precise color selection</li>
				<li>Undo/Redo functionality</li>
				<li>Zoom and pan controls for detailed work</li>
			</ul>

			<p className="mt-4">
				By using these image editing features, you can enhance your images and create precise init images and masks for image generation tasks in
				Protocraft. Experiment with different tools and techniques to achieve the desired results for your projects.
			</p>
		</div>
	);
};

export default EditingImages;
