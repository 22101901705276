import React from "react";
import ImageWithModal from "../../../common/ImageWithModal";

// Import images
import enableEditFileImg from "../../../../images/docs/file-browser/edit-tool-enabled.png";
import editToolExampleImg from "../../../../images/docs/file-browser/edit-tool-example-1.png";
import editToolFileDiffImg from "../../../../images/docs/file-browser/edit-tool-file-diff-1.png";

const EditToolAndFileDiffs = () => {
	return (
		<div>
			<h1 className="text-4xl font-bold mb-6">Edit Tool & File Diffs</h1>

			<p className="mb-4">
				Protocraft's Edit Tool permits the LLM to make changes to your files directly within the application. Any changes that the LLM makes will be
				provided in a review file diff, so you can reject, approve, or modify and approve the changes. This feature is particularly useful when you want
				to give the LLM agency and instructions to make a series of changes to one or more files.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Enabling the Edit File Tool</h2>
			<ImageWithModal src={enableEditFileImg} alt="Enable Edit File Tool" className="mb-4" />
			<p className="mb-4">
				Before you can use the Edit Tool, you need to enable it:
				<ol className="list-decimal list-inside ml-4 mt-2">
					<li>Click on the "Tools" button in the chat options interface.</li>
					<li>In the "Manage Tools" popup, find the "Edit File" option.</li>
					<li>Check the box next to "Edit File" to enable this feature.</li>
				</ol>
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Using the Edit Tool</h2>
			<ImageWithModal src={editToolExampleImg} alt="Edit Tool Example" className="mb-4" />
			<p className="mb-4">
				Once the Edit File tool is enabled, you can instruct the AI to make changes to your files. Here's how it works:
				<ol className="list-decimal list-inside ml-4 mt-2">
					<li>
						Include or specify (if the LLM has the File Directory Listing and permissions to read the files) the file you want to edit and describe
						the changes you want to make.
					</li>
					<li>The AI will process your request and generate the necessary edits.</li>
					<li>You will be prompted to confirm, reject, or modify the changes.</li>
				</ol>
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Reviewing File Diffs</h2>
			<ImageWithModal src={editToolFileDiffImg} alt="File Diff Example" className="mb-4" />
			<p className="mb-4">
				After the AI generates the edits, you'll be presented with a file diff view:
				<ul className="list-disc list-inside ml-4 mt-2">
					<li>The left side shows the original file content.</li>
					<li>The right side displays the proposed changes.</li>
					<li>Added lines are highlighted in green, while removed lines are highlighted in red.</li>
					<li>You can edit the content of the right side to modify the changes.</li>
					<li>You can review the changes carefully before deciding to accept or reject them.</li>
				</ul>
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Approving Changes</h2>
			<p className="mb-4">
				Once you've reviewed the changes:
				<ol className="list-decimal list-inside ml-4 mt-2">
					<li>Click the "Accept" button to apply the changes to your file.</li>
					<li>If you're not satisfied with the proposed edits, you can click "Reject" to discard them.</li>
					<li>
						After accepting or rejecting all changes, the File Diffs box in the chat window will reflect the approval or rejection state of each
						file.
					</li>
				</ol>
			</p>

			<p className="mt-4">
				The Edit Tool streamlines the process of implementing changes suggested by the AI, making it easier to iterate on your code or content within
				Protocraft. Always review the proposed changes carefully to ensure they align with your intentions and maintain the integrity of your files.
			</p>
		</div>
	);
};

export default EditToolAndFileDiffs;
