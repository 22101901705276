import React, { useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	return (
		<header className="bg-gradient-to-r from-purple-600 to-indigo-600 text-white shadow-md z-50 w-full electron-draggable-region">
			<div className="mx-auto px-4 py-4 flex flex-wrap items-center justify-between">
				<div className="flex items-center">
					<h1 className="text-2xl font-bold">
						<Link to="/" className="hover:text-gray-200 transition duration-200">
							://protocraft
						</Link>
					</h1>
				</div>
				<button className="md:hidden" onClick={toggleMenu} aria-label="Toggle menu">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
					</svg>
				</button>
				<nav className={`${isMenuOpen ? "block" : "hidden"} md:block w-full md:w-auto mt-4 md:mt-0`}>
					<ul className="flex flex-col md:flex-row md:space-x-6">
						<li>
							<Link to="/downloads" className="block py-2 px-4 md:p-0 hover:text-gray-200 transition duration-200">
								Download
							</Link>
						</li>
						<li>
							<Link to="/purchase" className="block py-2 px-4 md:p-0 hover:text-gray-200 transition duration-200">
								Buy
							</Link>
						</li>
						<li>
							<Link to="/docs" className="block py-2 px-4 md:p-0 hover:text-gray-200 transition duration-200">
								Docs
							</Link>
						</li>
						<li>
							<Link to="/faq" className="block py-2 px-4 md:p-0 hover:text-gray-200 transition duration-200">
								FAQ
							</Link>
						</li>
						<li>
							<Link to="/account" className="block py-2 px-4 md:p-0 hover:text-gray-200 transition duration-200">
								Account
							</Link>
						</li>
					</ul>
				</nav>
			</div>
		</header>
	);
};

export default Header;
