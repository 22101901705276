import React, { useState } from "react";

interface ImageWithModalProps {
	src: any;
	alt: string;
	className?: string;
}

const ImageWithModal: React.FC<ImageWithModalProps> = ({ src, alt, className }) => {
	const [isOpen, setIsOpen] = useState(false);

	const openModal = () => setIsOpen(true);
	const closeModal = () => setIsOpen(false);

	return (
		<>
			<div className="flex justify-center">
				<img src={src} alt={alt} className={`my-1 rounded cursor-pointer w-4/5 ${className || ""}`} onClick={openModal} />
			</div>
			{isOpen && (
				<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50" onClick={closeModal}>
					<img src={src} alt={alt} className="max-w-[90%] max-h-[90%] rounded-lg" onClick={(e) => e.stopPropagation()} />
				</div>
			)}
		</>
	);
};

export default ImageWithModal;
