import React from "react";
import ImageWithModal from "../../../common/ImageWithModal";
import localApiImageGen from "../../../../images/docs/settings/local-api-image-gen.png";

const SettingUpLocalImageGenerationAPI: React.FC = () => {
	return (
		<div>
			<h1 className="text-4xl font-bold mb-6">Setting Up Local Image Generation API</h1>

			<p>
				Protocraft supports multiple image generation providers, including local models. This guide will help you set up and configure image generation
				APIs.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Available Providers</h2>
			<p>Protocraft currently supports three image generation providers:</p>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>
					<strong>OpenAI DALL-E 2 and 3:</strong> Cloud-based image generation models by OpenAI.
				</li>
				<li>
					<strong>Hugging Face:</strong> Various models hosted on the Hugging Face platform.
				</li>
				<li>
					<strong>Local Models:</strong> Run models locally using AUTOMATIC1111's Stable Diffusion webui.
				</li>
			</ul>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Configuring Local Image Generation API</h2>
			<p>To set up a local image generation API, follow these steps:</p>

			<ImageWithModal src={localApiImageGen} alt="Local API Image Generation Settings" className="w-full mb-4" />

			<ol className="list-decimal list-inside ml-4 mb-4">
				<li>
					<strong>Connection Name:</strong> Enter a descriptive name for your local API connection (e.g., "My Stable Diffusion").
				</li>
				<li>
					<strong>API Type:</strong> Select "Image Generation" from the dropdown menu.
				</li>
				<li>
					<strong>API Program:</strong> Choose "AUTOMATIC1111 (Stable Diffusion WebUI)" from the options.
				</li>
				<li>
					<strong>URL:</strong> Enter the URL where your local Stable Diffusion WebUI is running (e.g., "http://localhost:7860").
				</li>
				<li>
					<strong>API Key (Optional):</strong> If you've set up authentication for your local API, enter the API key here. Otherwise, leave it blank.
				</li>
			</ol>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Running AUTOMATIC1111's Stable Diffusion WebUI</h2>
			<p>To use local models with AUTOMATIC1111's Stable Diffusion WebUI, you need to run the API with specific parameters:</p>
			<pre className="bg-gray-100 p-2 rounded">./webui.sh --api --listen</pre>
			<p>This command starts the WebUI with API access enabled and allows connections from other devices on your network.</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Adding and Removing Connections</h2>
			<p>
				Use the "Add New Connection" button to create additional API connections. To remove an existing connection, click the "Remove Connection" button
				next to the configuration you want to delete.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Saving Changes</h2>
			<p>After configuring your local image generation API, click the "Save Changes" button to apply and store your settings.</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Tips for Using Local Image Generation</h2>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>Ensure your local Stable Diffusion WebUI is running before attempting to generate images in Protocraft.</li>
				<li>If you're having trouble connecting, check that the URL is correct and that your firewall isn't blocking the connection.</li>
				<li>Experiment with different models and settings in the Stable Diffusion WebUI to find the best configuration for your needs.</li>
			</ul>
		</div>
	);
};

export default SettingUpLocalImageGenerationAPI;
