import React from "react";
import { Link } from "react-router-dom";
import alwaysIgnoreFilesImg from "../../../../images/docs/settings/always-ignore-files.png";
import ImageWithModal from "../../../common/ImageWithModal";

const AlwaysIgnoreFiles = () => {
	return (
		<div>
			<h1 className="text-4xl font-bold mb-6">Always Ignore Files</h1>

			<p>
				Protocraft allows you to set up system-wide ignore rules for files and directories. This feature helps you exclude certain files or folders from
				being included in the file browser or chat initialization directory listing.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Always Ignore Files Settings</h2>
			<p>In the Protocraft settings, navigate to the "Always Ignore Files" section. Here, you can set up and manage your ignore rules.</p>

			<ImageWithModal src={alwaysIgnoreFilesImg} alt="Always Ignore Files Settings" />

			<h2 className="text-2xl font-semibold mt-8 mb-4">Setting Up Ignore Rules</h2>
			<p>To set up ignore rules:</p>
			<ol className="list-decimal list-inside ml-4 mb-4">
				<li>In the text area, enter one rule per line.</li>
				<li>
					Use the same syntax as .gitignore files. For example:
					<ul className="list-disc list-inside ml-8 mb-2">
						<li>
							To ignore a specific file: <code>filename.ext</code>
						</li>
						<li>
							To ignore a directory: <code>directory_name/</code>
						</li>
						<li>
							To ignore all files with a certain extension: <code>*.ext</code>
						</li>
					</ul>
				</li>
				<li>
					You can use comments by starting a line with <code>#</code>.
				</li>
				<li>After entering your rules, click the "Save" button to apply the changes.</li>
			</ol>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Default Ignore Rules</h2>
			<p>Protocraft comes with some default ignore rules for common system and editor files. These typically include:</p>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>Operating System files (e.g., .DS_Store, Thumbs.db)</li>
				<li>Editor/IDE files (e.g., .vscode/, .idea/)</li>
				<li>Version control system files (e.g., .git/)</li>
			</ul>
			<p>
				Let us know if certain default ignore rules are causing issues in your default workspace - we're looking to find the best starting set of rules
				without excluding required files.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Impact of Ignore Rules</h2>
			<p>Files and directories matching these ignore rules will be:</p>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>Excluded from the file browser in Protocraft</li>
				<li>Not included in chat initialization directory listings</li>
				<li>Ignored when Protocraft is scanning or processing files in your workspace</li>
			</ul>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Customizing Ignore Rules</h2>
			<p>You can customize the ignore rules to fit your specific needs. For example:</p>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>Ignore large binary files that aren't relevant to your work</li>
				<li>Exclude sensitive information or configuration files</li>
				<li>Ignore temporary or cache files specific to your development environment</li>
			</ul>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Per-Workspace Ignore Rules</h2>
			<p>
				You can also set ignore rules on a per-workspace basis. This is useful if you want to exclude certain files or directories in a specific
				workspace. Learn how to set project ignore rules here:{" "}
				<Link to="/docs/setting-up-project-ignore-rules" className="text-blue-500 hover:underline">
					Setting Up Project Ignore Rules
				</Link>
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Next Steps</h2>
			<p>
				After setting up your ignore rules, you might want to explore other Protocraft settings, such as{" "}
				<Link to="/docs/setting-up-api-keys" className="text-blue-500 hover:underline">
					Setting Up API Keys
				</Link>{" "}
				or{" "}
				<Link to="/docs/setting-up-local-llm" className="text-blue-500 hover:underline">
					Setting Up a Local LLM
				</Link>
				.
			</p>
		</div>
	);
};

export default AlwaysIgnoreFiles;
