import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import { FaEnvelope, FaLock, FaUser } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider";
import { call } from "../../services/api";

interface Key {
	license_id: string;
	license_key: string;
	max_activations: number;
	created_at: string;
	updated_at: string;
	issued_at: string;
	activated_at: string;
	expires_at: string;
	license_type: "trial" | "personal" | "team";
	email: string;
	activation_count: string;
	activations: { activation_date: string }[];
}

interface AccountInfo {
	email: string;
	created_at: string;
	updated_at: string;
	keys: Key[];
}

const Account = () => {
	const { email, setEmail, signIn, verify, resendVerification, isLoggedIn, loginCode, acceptedEula, acceptEula } = useAuth();
	const [phase, setPhase] = useState(isLoggedIn ? 3 : 1);
	const [error, setError] = useState("");
	const [code, setCode] = useState("");
	const [countdown, setCountdown] = useState(30);
	const [accountInfo, setAccountInfo] = useState<AccountInfo | null>(null);
	const [copiedKeys, setCopiedKeys] = useState<{ [key: string]: boolean }>({});
	const location = useLocation();

	useEffect(() => {
		if (isLoggedIn && loginCode) {
			setPhase(3);
			fetchAccountInfo();
		}
	}, [isLoggedIn, loginCode]);

	const fetchAccountInfo = async () => {
		try {
			const response: AccountInfo = await call("account/get-info", "POST", { email, code: loginCode });
			setAccountInfo(response);
		} catch (err) {
			setError("Failed to fetch account information. Please try again.");
		}
	};

	const handleEmailSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setError("");
		try {
			await signIn(email);
			setPhase(2);
			startCountdown();
		} catch (err) {
			setError("Failed to send email. Please try again.");
		}
	};

	const handleCodeSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setError("");
		try {
			await verify(code);
		} catch (err) {
			setError("Failed to verify code. Please try again.");
		}
	};

	const handleResendEmail = async () => {
		try {
			await resendVerification();
			startCountdown();
		} catch (err) {
			setError("Failed to resend email. Please try again.");
		}
	};

	const startCountdown = () => {
		setCountdown(30);
		const timer = setInterval(() => {
			setCountdown((prevCountdown) => {
				if (prevCountdown <= 1) {
					clearInterval(timer);
					return 0;
				}
				return prevCountdown - 1;
			});
		}, 1000);
	};

	const handleAcceptEula = async () => {
		try {
			await acceptEula();
			fetchAccountInfo(); // Refresh account info after accepting EULA
		} catch (err) {
			setError("Failed to accept EULA. Please try again.");
		}
	};

	const renderKeyInfo = (key: Key) => {
		const copyToClipboard = (text: string) => {
			navigator.clipboard.writeText(text).then(
				() => {
					setCopiedKeys((prev) => ({ ...prev, [key.license_id]: true }));
					setTimeout(() => {
						setCopiedKeys((prev) => ({ ...prev, [key.license_id]: false }));
					}, 5000);
				},
				(err) => {
					console.error("Could not copy text: ", err);
				},
			);
		};

		return (
			<div key={key.license_id} className="bg-gray-100 p-4 rounded-lg mb-4">
				<p className="flex flex-row">
					<strong>License Key:</strong>{" "}
					<span className="flex items-center">
						<span
							className="cursor-pointer select-all bg-gray-200 mx-2 px-1"
							onClick={() => copyToClipboard(key.license_key)}
							title="Click to copy"
						>
							{key.license_key}
						</span>
						{copiedKeys[key.license_id] && <span className="text-gray-700 text-sm animate-fade-out">Copied to clipboard</span>}
					</span>
				</p>
				<p>
					<strong>Type:</strong> {key.license_type && key.license_type.charAt(0).toUpperCase() + key.license_type.slice(1)}
				</p>
				<p>
					<strong>Expires:</strong> {new Date(key.expires_at).toLocaleDateString()}
				</p>
				<p>
					<strong>Activations:</strong> {key.activation_count} / {key.max_activations}
				</p>
			</div>
		);
	};

	const renderAccountInfo = () => {
		const searchParams = new URLSearchParams(location.search);
		const checkoutSuccess = searchParams.get("checkout_success") === "true";

		if (process.env.NODE_ENV === "production" && checkoutSuccess) {
			ReactGA.event({
				category: "conversion",
				action: "complete_checkout",
				label: "User completed checkout",
			});
		}

		return (
			<div>
				<h3 className="text-2xl font-semibold mb-4 flex items-center">
					<FaUser className="mr-2" /> Account Information
				</h3>
				<p>
					<strong>Email:</strong> {accountInfo?.email}
				</p>
				<p>
					<strong>Created:</strong> {new Date(accountInfo?.created_at || "").toLocaleDateString()}
				</p>

				{acceptedEula ? (
					<>
						<h4 className="text-xl font-semibold mt-4 mb-2">License Keys</h4>
						{accountInfo?.keys && accountInfo.keys.length > 0 ? accountInfo.keys.map(renderKeyInfo) : <p>No license keys found.</p>}
						<div className="mt-4 space-x-4">
							<Link
								to="/purchase"
								className="px-4 py-2 bg-gradient-to-r from-purple-600 to-pink-500 hover:from-purple-700 hover:to-pink-600 text-white rounded-lg transition duration-200"
							>
								Purchase Key
							</Link>
						</div>
					</>
				) : (
					<div className="mt-4">
						<p className="text-yellow-600">Please accept the EULA to view your license keys.</p>
						<button
							onClick={handleAcceptEula}
							className="mt-2 px-4 py-2 bg-gradient-to-r from-purple-600 to-pink-500 hover:from-purple-700 hover:to-pink-600 text-white rounded-lg transition duration-200"
						>
							Accept EULA
						</button>
					</div>
				)}
			</div>
		);
	};

	return (
		<div className="container mx-auto p-6 max-w-6xl">
			<h2 className="text-4xl font-bold text-center mb-8 text-purple-600 dark:text-purple-400">Account Information</h2>
			<p className="text-xl text-center mb-12 text-gray-700 dark:text-gray-300">View your account details or sign in to access your information.</p>

			<div className={`mx-auto bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg ${phase !== 3 ? "max-w-md" : ""}`}>
				{phase === 1 && (
					<div>
						<h3 className="text-2xl font-semibold mb-4 flex items-center text-purple-600 dark:text-purple-400">
							<FaEnvelope className="mr-2" /> Sign-in with your Email
						</h3>
						<form onSubmit={handleEmailSubmit}>
							<input
								type="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								placeholder="Enter your email"
								className="w-full p-3 mb-4 border rounded-lg dark:bg-gray-700 dark:text-white"
								required
							/>
							<button
								type="submit"
								className="w-full p-3 bg-gradient-to-r from-purple-600 to-pink-500 hover:from-purple-700 hover:to-pink-600 text-white rounded-lg transition duration-200"
							>
								Get Account Info
							</button>
						</form>
					</div>
				)}

				{phase === 2 && (
					<div>
						<h3 className="text-2xl font-semibold mb-4 flex items-center text-purple-600 dark:text-purple-400">
							<FaLock className="mr-2" /> Enter Verification Code
						</h3>
						<p className="mb-4 text-gray-700 dark:text-gray-300">Please check your email for a 6-digit code and enter here to continue.</p>
						<form onSubmit={handleCodeSubmit} className="mb-4">
							<input
								type="text"
								value={code}
								onChange={(e) => setCode(e.target.value)}
								placeholder="Enter 6-digit code"
								className="w-full p-3 mb-4 border rounded-lg dark:bg-gray-700 dark:text-white"
								required
								maxLength={6}
							/>
							<button
								type="submit"
								className="w-full p-3 bg-gradient-to-r from-purple-600 to-pink-500 hover:from-purple-700 hover:to-pink-600 text-white rounded-lg transition duration-200"
							>
								Verify Code
							</button>
						</form>
						<button
							onClick={handleResendEmail}
							disabled={countdown > 0}
							className={`w-full p-3 ${countdown > 0 ? "bg-gray-400" : "bg-gradient-to-r from-purple-600 to-pink-500 hover:from-purple-700 hover:to-pink-600"} text-white rounded-lg transition duration-200`}
						>
							{countdown > 0 ? `Resend email in ${countdown}s` : "Resend email"}
						</button>
					</div>
				)}

				{phase === 3 && accountInfo && renderAccountInfo()}

				{error && <p className="text-red-600 mt-4 text-center">{error}</p>}
			</div>

			<div className="mt-8 text-center">
				<Link to="/" className="text-purple-600 hover:text-purple-800 transition duration-200">
					← Back to Home
				</Link>
			</div>
		</div>
	);
};

export default Account;
