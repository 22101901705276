import React from "react";
import ImageWithModal from "../../../common/ImageWithModal";

// Import images (you'll need to add these to your project)
import previewScreenshotButtonImg from "../../../../images/docs/file-browser/preview-view-screenshot-button.png";
import previewScreenshotWindowImg from "../../../../images/docs/file-browser/preview-view-screenshot-window.png";

const UsingPreviewScreenshots = () => {
	return (
		<div>
			<h1 className="text-4xl font-bold mb-6">Using Preview Screenshots</h1>

			<p className="mb-4">
				Protocraft allows you to take screenshots of file previews and use them in your chat context. This feature is particularly useful when you want
				to discuss specific visual elements or ask for changes based on what you see in a file preview.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Taking a Screenshot</h2>
			<ImageWithModal src={previewScreenshotButtonImg} alt="Preview Screenshot Button" className="mb-4" />
			<p className="mb-4">
				To take a screenshot of the preview window content:
				<ol className="list-decimal list-inside ml-4 mt-2">
					<li>Open a file that supports preview mode (e.g., HTML or SVG files).</li>
					<li>Enable preview mode by clicking the "Preview" button in the file viewer header.</li>
					<li>Click the camera icon in the file viewer header to take a screenshot.</li>
				</ol>
			</p>
			<p className="mb-4">The screenshot will be automatically attached to the chat context, allowing you to reference it in your prompts.</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Viewing the Screenshot</h2>
			<ImageWithModal src={previewScreenshotWindowImg} alt="Preview Screenshot Window" className="mb-4" />
			<p className="mb-4">
				To view the screenshot you've taken:
				<ol className="list-decimal list-inside ml-4 mt-2">
					<li>Look for the eye icon next to the screenshot attachment in the chat.</li>
					<li>Click on the eye icon to open an image viewer window.</li>
					<li>The screenshot will be displayed in full.</li>
					<li>You can also download the screenshot for later use.</li>
				</ol>
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Using Screenshots in Prompts</h2>
			<p className="mb-4">
				Once you've taken a screenshot, you can use it in your prompts in various ways:
				<ul className="list-disc list-inside ml-4 mt-2">
					<li>Ask for analysis of the information displayed in the image.</li>
					<li>Request changes or improvements based on what's shown in the screenshot.</li>
					<li>Compare multiple screenshots to highlight differences or similarities.</li>
				</ul>
			</p>
			<p className="mb-4">
				You can include multiple screenshots in a single prompt, allowing for more complex discussions or comparisons. However, keep in mind that the
				number of screenshots you can include are limited by the LLM, usually 5-10.
			</p>

			<p className="mt-4">
				By using preview screenshots, you can have more precise and context-rich conversations about the visual aspects of your files, making it easier
				to collaborate on design changes or analyze visual data within Protocraft.
			</p>
		</div>
	);
};

export default UsingPreviewScreenshots;
