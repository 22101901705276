import React from "react";
import { Link } from "react-router-dom";

const LicensingAndPricing = () => {
	return (
		<div>
			<h1 className="text-4xl font-bold mb-6">Licensing, Free Evaluation, and Pricing</h1>

			<h2 className="text-2xl font-semibold mt-6 mb-4">Free Evaluation</h2>
			<p className="mb-4">Protocraft offers a free evaluation period for users to try out the software before purchasing a license:</p>
			<ul className="list-disc pl-6 mb-4">
				<li>During the evaluation period, you can use all the features of Protocraft for free.</li>
				<li>This evaluation period allows you to fully explore Protocraft's capabilities and determine if it meets your needs.</li>
				<li>A license is needed to use Protocraft for business, commercial, educational, or professional use.</li>
				<li>No credit card, payment, or personal information is required to start your evaluation.</li>
			</ul>

			<h2 className="text-2xl font-semibold mt-6 mb-4">Licensing Options</h2>
			<p className="mb-4">
				After the evaluation period, you'll need to purchase a license to continue using Protocraft. We offer the following licensing options:
			</p>
			<ul className="list-disc pl-6 mb-4">
				<li>
					<strong>Individual License:</strong> For personal or professional use by a single user.
				</li>
				<li>
					<strong>Team License:</strong> For businesses and organizations with multiple users.
				</li>
				<li>
					<strong>Enterprise License:</strong> Custom solutions for large-scale deployments.
				</li>
			</ul>

			<h2 className="text-2xl font-semibold mt-6 mb-4">Pricing</h2>
			<p className="mb-4">Our current pricing structure is as follows:</p>
			<ul className="list-disc pl-6 mb-4">
				<li>
					<strong>Individual License:</strong> $39 per user, includes 1 year of updates
				</li>
				<li>
					<strong>Team License:</strong> Please{" "}
					<a href="mailto:sales@protocraft.ai" className="text-purple-600 hover:text-purple-800">
						contact our sales team
					</a>{" "}
					for custom pricing based on your team size
				</li>
				<li>
					<strong>Enterprise License:</strong> Please{" "}
					<a href="mailto:sales@protocraft.ai" className="text-purple-600 hover:text-purple-800">
						contact our sales team
					</a>{" "}
					for custom pricing and deployment options
				</li>
			</ul>

			<h2 className="text-2xl font-semibold mt-6 mb-4">What's Included in the License</h2>
			<ul className="list-disc pl-6 mb-4">
				<li>Full access to all features of Protocraft</li>
				<li>1 year of software updates</li>
			</ul>

			<h2 className="text-2xl font-semibold mt-6 mb-4">Renewal and Upgrades</h2>
			<p className="mb-4">After your initial 1-year license period:</p>
			<ul className="list-disc pl-6 mb-4">
				<li>You can continue to use the version of Protocraft you have installed indefinitely.</li>
				<li>To continue to use Protocraft for business, commercial, educational, or professional use, you'll need to renew your license.</li>
			</ul>

			<h2 className="text-2xl font-semibold mt-6 mb-4">How to Purchase</h2>
			<p className="mb-4">To purchase a license for Protocraft:</p>
			<ol className="list-decimal pl-6 mb-4">
				<li>
					Visit our{" "}
					<Link to="/purchase" className="text-purple-600 hover:text-purple-800">
						Purchase page
					</Link>
					.
				</li>
				<li>Complete the checkout process.</li>
				<li>You'll receive a license key via email, which you can use to activate Protocraft.</li>
				<li>
					You can also sign into the{" "}
					<Link to="/account" className="text-purple-600 hover:text-purple-800">
						Account page
					</Link>{" "}
					with your email to view your licenses.
				</li>
			</ol>

			<p className="mt-6">
				If you have any questions about licensing or pricing, please don't hesitate to contact our sales team at{" "}
				<a href="mailto:sales@protocraft.ai" className="text-purple-600 hover:text-purple-800">
					sales@protocraft.ai
				</a>
				.
			</p>
		</div>
	);
};

export default LicensingAndPricing;
