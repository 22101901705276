import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { call } from "../../services/api";

interface LicenseKey {
	license_id: string;
	license_key: string;
	max_activations: number;
	created_at: string;
	updated_at: string;
	issued_at: string;
	activated_at: string;
	expires_at: string;
	license_type: "trial" | "personal" | "team";
	email: string;
	activation_count: string;
}

const PurchaseSuccess: React.FC = () => {
	const location = useLocation();
	const [licenseKey, setLicenseKey] = useState<LicenseKey | null>(null);
	const [error, setError] = useState<string>("");
	const [copied, setCopied] = useState(false);
	const [attempts, setAttempts] = useState(0);
	const [isLoading, setIsLoading] = useState(true);

	const fetchLicenseKey = useCallback(
		async (orderId: string, isGumroad: boolean) => {
			try {
				let response: LicenseKey;
				if (isGumroad) {
					response = await call("gumroad/get-gumroad-order", "POST", { sale_id: orderId });
				} else {
					response = await call("stripe/get-by-order-id", "POST", { sessionId: orderId });
				}
				setLicenseKey(response);
				setIsLoading(false);
			} catch (err) {
				setAttempts((prev) => prev + 1);
				if (attempts >= 4) {
					setError(
						`Unable to fetch license key. If this issue persists, please contact us at <a href="mailto:support@protocraft.ai?subject=License%20Key%20Issue&body=Order%20ID:%20${orderId}" class="text-blue-500 hover:underline">support@protocraft.ai</a>.`,
					);
					setIsLoading(false);
				}
			}
		},
		[attempts],
	);

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const sessionId = searchParams.get("session_id");
		const saleId = searchParams.get("sale_id");

		if (saleId) {
			fetchLicenseKey(saleId, true);
		} else if (sessionId) {
			fetchLicenseKey(sessionId, false);
		} else {
			setError("No order ID found in URL.");
			setIsLoading(false);
		}
	}, []);

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const orderId = searchParams.get("session_id") || searchParams.get("sale_id");
		const isGumroad = !!searchParams.get("sale_id");

		if (!orderId) {
			setError("No order ID found in URL.");
			setIsLoading(false);
			return;
		}

		const interval = setInterval(() => {
			if (attempts < 5 && !licenseKey && isLoading) {
				fetchLicenseKey(orderId, isGumroad);
			} else {
				clearInterval(interval);
			}
		}, 3000);

		return () => clearInterval(interval);
	}, [location, attempts, licenseKey, fetchLicenseKey, isLoading]);

	const copyToClipboard = () => {
		if (licenseKey) {
			navigator.clipboard.writeText(licenseKey.license_key).then(
				() => {
					setCopied(true);
					setTimeout(() => setCopied(false), 3000);
				},
				() => {
					setError("Failed to copy to clipboard");
				},
			);
		}
	};

	const renderContent = () => {
		if (error) {
			return <div className="text-red-600 text-center" dangerouslySetInnerHTML={{ __html: error }} />;
		}

		if (isLoading) {
			return (
				<div className="text-center text-gray-700 dark:text-gray-300">
					<p className="mb-4">We're processing your payment and generating your license key.</p>
					<p className="mb-4">This may take a few moments. Please don't close this page.</p>
					<div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500 mx-auto"></div>
				</div>
			);
		}

		if (!licenseKey) {
			return <div className="text-red-600 text-center">Unable to retrieve license key. Please try refreshing the page or contact support.</div>;
		}

		return (
			<div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg">
				<div className="mb-4">
					<strong className="text-gray-700 dark:text-gray-300">License Key:</strong>
					<div className="flex items-center mt-2">
						<input
							type="text"
							value={licenseKey.license_key}
							readOnly
							className="flex-grow p-2 border rounded-l-lg bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300"
						/>
						<button
							onClick={copyToClipboard}
							className="bg-gradient-to-r from-purple-600 to-pink-500 hover:from-purple-700 hover:to-pink-600 text-white px-4 py-2 rounded-r-lg transition duration-200"
						>
							{copied ? "Copied!" : "Copy"}
						</button>
					</div>
				</div>
				<p className="text-gray-700 dark:text-gray-300">
					<strong>Type:</strong> {licenseKey.license_type.charAt(0).toUpperCase() + licenseKey.license_type.slice(1)}
				</p>
				<p className="text-gray-700 dark:text-gray-300">
					<strong>Expires:</strong> {new Date(licenseKey.expires_at).toLocaleDateString()}
				</p>
				<p className="text-gray-700 dark:text-gray-300">
					<strong>Activations:</strong> {licenseKey.activation_count || 0} / {licenseKey.max_activations}
				</p>
			</div>
		);
	};

	return (
		<div className="container mx-auto p-6 max-w-2xl">
			<h2 className="text-4xl font-bold text-center mb-8 text-purple-600 dark:text-purple-400">Your License Key</h2>
			{renderContent()}
		</div>
	);
};

export default PurchaseSuccess;
