import React from "react";
import { Link } from "react-router-dom";
import localAPIBeforeAddImg from "../../../../images/docs/settings/local-api-before-add.png";
import localAPIAtAddImg from "../../../../images/docs/settings/local-api-at-add.png";
import localLLMAddModelImg from "../../../../images/docs/settings/local-llm-add-model.png";
import localLLMAfterAddModelImg from "../../../../images/docs/settings/local-llm-after-add-model.png";
import ImageWithModal from "../../../common/ImageWithModal";

const SettingUpLocalLLM = () => {
	return (
		<div>
			<h1 className="text-4xl font-bold mb-6">Setting Up a Local LLM</h1>

			<p>
				Protocraft allows you to use your own local Large Language Models (LLMs) for AI interactions. This page will guide you through the process of
				setting up and managing your local LLMs.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Step 1: Setting Up Local & Custom APIs</h2>
			<p>Before adding a local LLM, you need to set up an API connection. Navigate to the "Local & Custom APIs" section in the Protocraft settings.</p>

			<ImageWithModal src={localAPIBeforeAddImg} alt="Local API Settings Before Adding" />

			<p>To add a new API connection:</p>
			<ol className="list-decimal list-inside ml-4 mb-4">
				<li>Click the "Add New Connection" button.</li>
				<li>
					Fill in the following details:
					<ul className="list-disc list-inside ml-8 mb-2">
						<li>Connection Name (e.g., "Local Ollama")</li>
						<li>API Type: Select "Large Language Model" from the dropdown menu.</li>
						<li>URL (e.g., "http://localhost:11434/v1/" for local Ollama)</li>
						<li>API Key (e.g., "ollama" for Ollama)</li>
					</ul>
				</li>
				<li>Click on "Save" to save the connection.</li>
			</ol>

			<ImageWithModal src={localAPIAtAddImg} alt="Adding Local API Connection" />

			<h3 className="text-xl font-semibold mt-6 mb-2">Ollama Configuration</h3>
			<p>If you're using Ollama:</p>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>
					For Ollama running locally on the same computer:
					<ul className="list-disc list-inside ml-8 mb-2">
						<li>URL: http://localhost:11434/v1/</li>
						<li>API Key: ollama</li>
					</ul>
				</li>
				<li>
					For Ollama running on another computer:
					<ul className="list-disc list-inside ml-8 mb-2">
						<li>URL: http://[ip address]:11434/v1/</li>
						<li>API Key: ollama (or as configured on the remote machine)</li>
					</ul>
				</li>
			</ul>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Step 2: Adding a Local LLM</h2>
			<p>After setting up the API connection, navigate to the "Local & Custom LLMs" section to add your local LLM.</p>

			<ImageWithModal src={localLLMAddModelImg} alt="Adding Local LLM" />

			<p>To add a new local LLM:</p>
			<ol className="list-decimal list-inside ml-4 mb-4">
				<li>
					Fill in the following details:
					<ul className="list-disc list-inside ml-8 mb-2">
						<li>Model Name (e.g., "Llama 3.1 - 8b")</li>
						<li>Model ID (e.g., "llama3.1", or "llama3.1:8b", for example)</li>
						<li>Context Length (e.g., 128000)</li>
						<li>Prompt Price and Completion Price (if applicable)</li>
						<li>Select the Connection you created in Step 1</li>
					</ul>
				</li>
				<li>Check the appropriate boxes for "Has Image Vision Support" and "Can Use Tools" based on your model's capabilities.</li>
				<li>Click the "Add Model" button to save your new local LLM.</li>
			</ol>

			<p className="mt-4">
				<strong>Important Note:</strong> The Model ID should match the ID of the model you've downloaded on Ollama or the ID you use as the "model"
				parameter when making API calls for LLM chat requests. For example, if you've downloaded the Llama 2 model on Ollama and use "llama2" in your
				API calls, you should use "llama2" as the Model ID here.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Managing Local LLMs</h2>
			<p>After adding a local LLM, you can manage it as follows:</p>

			<ImageWithModal src={localLLMAfterAddModelImg} alt="Local LLM After Adding" />

			<ul className="list-disc list-inside ml-4 mb-4">
				<li>View all your added models in the list above the "Add New Model" section.</li>
				<li>To remove a model, click the "Remove Model" button next to the model in the list.</li>
				<li>To edit a model, you may need to remove it and add it again with the updated information.</li>
			</ul>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Next Steps</h2>
			<p>
				Once you've set up your local LLM, you can start using it for AI interactions within Protocraft. If you haven't already, you might also want to
				check out how to{" "}
				<Link to="/docs/setting-up-api-keys" className="text-blue-500 hover:underline">
					Set Up API Keys
				</Link>{" "}
				for external services.
			</p>
		</div>
	);
};

export default SettingUpLocalLLM;
