import React from "react";
import ImageWithModal from "../../../common/ImageWithModal";
import initSettingsImg from "../../../../images/docs/using/init-settings.png";
import initSettingsDateTimeImg from "../../../../images/docs/using/init-settings-date-time.png";

const SettingInitializationRules = () => {
	return (
		<div>
			<h1 className="text-4xl font-bold mb-6">Setting Initialization Rules</h1>

			<p className="mb-4">
				Initialization rules in Protocraft allow you to configure the initial data provided to the Large Language Model (LLM) at the start of each chat
				thread. These settings can enhance the AI's context awareness and improve its ability to assist you with specific tasks.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Accessing Initialization Settings</h2>
			<ImageWithModal src={initSettingsImg} alt="Initialization Settings" className="mb-4" />
			<p className="mb-4">To access the initialization settings:</p>
			<ol className="list-decimal list-inside ml-4 mb-4">
				<li>Look for the "Initialize" button at the bottom of the chat interface.</li>
				<li>Click on the button to open the Initialization Settings panel.</li>
			</ol>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Available Initialization Options</h2>
			<p className="mb-4">Protocraft offers the following initialization options:</p>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>
					<strong>Share Date and Time:</strong> When enabled, this option shares the current date and time with the LLM. This is particularly useful
					when you need the AI to be aware of the current time context, such as for scheduling tasks, discussing current events, or using the Google
					search tool and browse internet tools when performing research tasks.
				</li>
				<li>
					<strong>Include Directory Listing:</strong> This option includes the file and folder structure of your current working directory. It's
					beneficial when using tools like "Read File" or when working on projects that involve file management.
				</li>
			</ul>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Using Initialization Settings</h2>
			<ImageWithModal src={initSettingsDateTimeImg} alt="Date and Time Initialization" className="mb-4" />
			<p className="mb-4">Here's how to use the initialization settings effectively:</p>
			<ol className="list-decimal list-inside ml-4 mb-4">
				<li>Open the Initialization Settings panel.</li>
				<li>Check the boxes next to the options you want to enable.</li>
				<li>Start a new chat thread or continue your current conversation.</li>
				<li>The LLM will now have access to the selected information at the beginning of each interaction.</li>
			</ol>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Benefits of Using Initialization Rules</h2>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>
					<strong>Improved Context Awareness:</strong> By providing current date and time, the AI can give more relevant and timely responses.
				</li>
				<li>
					<strong>Enhanced File Management:</strong> Including directory listings helps the AI understand your project structure, making file-related
					tasks more efficient.
				</li>
				<li>
					<strong>Consistent Information:</strong> Initialization rules ensure that the AI has access to the same baseline information at the start of
					each conversation.
				</li>
				<li>
					<strong>Reduced Repetition:</strong> You don't need to manually inform the AI about these details in every conversation.
				</li>
			</ul>

			<p className="mt-4">
				By effectively using initialization rules, you can significantly improve the AI's ability to assist you with context-aware and file-related
				tasks in Protocraft.
			</p>
		</div>
	);
};

export default SettingInitializationRules;
